<template>
  <div class="main00">
    <div class="main01 home">

      <h1 class="text-3xl">
        <div class="top">Vue.js&nbsp;(&nbsp;HTML, CSS, JavaScript&nbsp;)</div>
        <div>リファレンス</div>
      </h1>

  </div>
</div>
</template>