<template>
  <a class="goto-top" href="#layout">></a>
</template>

<style scoped>
a.goto-top {
  z-index: 9999;
  background-color: #009b63;
  position: fixed;
  right: 15px;
  bottom: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 50px;
  height: 50px;
  border-radius: 50px;
  opacity: .62;
  cursor: pointer;

  color: white;
  font-weight: 900;
  transform: rotate(270deg);

  /* ここに直接書かないと消えなかった */
  @media print {
    display: none;
  }

  @apply no-underline;
}
</style>
