<template>
<div class="mb-16">
  <template v-for="(v, i) in props.list" :key="i">
    <article>
      <h2 class="itemsheader_html">{{v.title}}</h2>
      <div class="itemsbody mb-8">
        <ul class="itemsmokuji">
          <template v-for="(value, idx) in v.list" :key="idx">
            <li>
              <a class="a" :href="'#'+value.id">{{value.id}}</a>&nbsp;...
              {{value.title}}
            </li>
          </template>
        </ul>
      </div>
    </article>
  </template> 
</div>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  list: { type: Array }
});
</script>

<style scoped>
h2.itemsheader_html {
  /* background-color: #ff0066;*/
  background-color: #009b63;
  color: #ffffff;
  border-radius: 8px 8px 0px 0px;
  margin: 0;
  padding: 8px 8px 8px 8px;
  font-size: 95%;
  line-height: 1.5;
}
.itemsbody {
  background-color: #eeeeee;
  padding: 10px 10px 10px 10px;
  line-height: 1.5;
  text-align: left;
}
ul.itemsmokuji {
  list-style-type: none;
  margin: 0;
  padding: 0;
  li {
    display: list-item;
    text-align: -webkit-match-parent;
    unicode-bidi: isolate;
    a:link {
      color: #0000ff;
    }
  }
}
</style>