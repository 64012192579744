import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import HtmlView from '../views/HtmlView.vue'
import CssView from '../views/CssView.vue'
import JavascriptView from '../views/JavascriptView.vue'
import VueView from        '../views/VueView.vue'
import UseRouteSampleGirlfriendView from '../views/UseRouteSampleGirlfriendView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  }, {
    path: '/html',
    name: 'html',
    component: HtmlView
  }, {
    path: '/cascading-style-sheets',
    name: 'cascading-style-sheetsstyle',
    component: CssView
  }, {
    path: '/javascript',
    name: 'javascript',
    component: JavascriptView
  }, {
    path: '/vue',
    name: 'vue',
    component: VueView
  }, {
    path: '/girlfriend/:present*',
    name: 'Girlfriend',
    component: UseRouteSampleGirlfriendView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
