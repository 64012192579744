<template>
  <Vue />  
</template>

<script lang="ts">
import { defineComponent } from 'vue';

// Components
import Vue from '@/components/views/VueComponent.vue';

export default defineComponent({
  components: {
    Vue,
  },
});
</script>
