import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "prompt-header" }

import type { ListType, InListType } from "@/types/reference.type";
import { ref, computed, PropType } from 'vue';
import type { Ref } from 'vue';
import { useStore } from 'vuex';
import Gaiyou from "@/components/example/GaiyouComponents.vue";
import Code from "@/components/CodeComponent.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ExampleTempComponents',
  props: {
  id: { type: String, default: "" },
  list: { type: Array as PropType<ListType[]>, default: () => [] },
  pdfNextPageShow: { type: Boolean, default: false },
  isnocode: { type: Boolean, default: false },
  isnoexample: { type: Boolean, default: false },
  isnoshow: { type: Boolean, default: false },
  isonlyshow: { type: Boolean, default: false },
  isnobrowsershowcheckbox: { type: Boolean, default: false },
  isnospacemargintop: { type: Boolean, default: false },
  isnobrowsertitle: { type: Boolean, default: false },
  isjscode: { type: Boolean, default: false },
},
  setup(__props) {

const store = useStore();

const props = __props;

function getXxxExampleClassName() {
  let className = ""
  if ("<"===props.id.substring(0, 1) && ">"===props.id.substring(props.id.length-1)) {
    className = props.id.replace(/<|>/g , "")+'-example'
  }
  if (props.isnospacemargintop) {
    className = className + " mt-[-30px]"
  }
  return className
}
// function getId(id: string) {
//   const l = props.list.find((o: ListType) => {
//     return o.list.find((obj: InListType) => {
//       if (obj.id===id) {
//         return o
//       }
//     })
//   }) 
//   const result = l!.list.find((obj: InListType) => {
//     if (obj.id === id) {
//       return obj.id
//     }
//   })
//   return result?.id
// }
function getTitle(id: string) {
  const l = props.list.find((o: ListType) => {
    return o.list.find((obj: InListType) => {
      if (obj.id===id) {
        return o
      }
    })
  }) 
  if (!l) {
    return "";
  }
  const result = l.list.find((o: InListType) => {
    if (o.id === id) {
      return o.title
    }
  })
  return result?.title ?? ""
}
function getDescription(id: string) {
  const l = props.list.find((o: ListType) => {
    return o.list.find((obj: InListType) => {
      if (obj.id===id) {
        return o
      }
    })
  }) 
  if (!l) {
    return "";
  }
  const result = l.list.find((o: InListType) => {
    if (o.id === id) {
      return o.description
    }
  })
  return result?.description ?? ""
}


const highlight = computed(() => store.getters.highlight);
const highlightToggle = () => {
  store.dispatch('highlightToggle');
}

const templateCodeElement: Ref<null | HTMLDivElement> = ref(null);
const templateShowElement: Ref<null | HTMLDivElement> = ref(null); // ★

const scriptCodeElement: Ref<null | HTMLDivElement> = ref(null);
const scriptShowElement: Ref<null | HTMLDivElement> = ref(null); // ★

const styleCodeElement: Ref<null | HTMLDivElement> = ref(null);
const styleShowElement: Ref<null | HTMLDivElement> = ref(null); // ★

// ● [xx]aaa[/xx]を削除
// . ... 任意の1文字、+ ... 直前のパターンの1回以上繰り返し
// ? ... 直前のパターンの0～1回繰り返し
// .replace( /\[hL\](.+?)\[\/hL\]/g , "" );

const removeHtml = (v: string) => {
  // const a = v
  //   .replace( /^<pre><pre>/s , "" )
  //   .replace( /<\/pre><\/pre>$/s , "" )
  //   // .replace( /^<pre>/s , "" )
  //   // .replace( /<\/pre>$/s , "" )
  // console.log(a)

  return v//.replace( /<!--(.+?)-->/g , "" )
    // やめた <pre data-v-****>にもマッチするように( .replace( /^<pre[^>]*>/s , "" ) )。
    .replace( / data-v-(.+?)=""/g , "" )
    .replace( /^<pre>/s , "" )
    .replace( /<\/pre>$/s , "" )
    // ※ 決まり事: コンポーネントの場合<pre class="base">で囲まないといけない。
    .replace(/<pre class="base">(.+?)<\/pre>\n/s, (match, $1) => {
      return $1;
    })

    // ↓ ↓ ↓
    // ↓ ↓ ↓
    // ↓ ↓ ↓
    // --------------------
    //  第一階層(親要素)
    // --------------------
    // ※ 入れ子に注意: my-code-xxxとmy-show-xxx-2に分けた。
    // ※ 決まり事: <my-code-only>タグの前にスペースを置かないこと。
    // ※ 決まり事: <my-code-only>タグの前にスペースを置かないこと。
    // ※ 決まり事: <my-code-only>タグの前にスペースを置かないこと。
    // s: ドット（.）が改行文字にも一致
    .replace(/<my-code-only>(.+?)<\/my-code-only>\n/gs, (match, $1) => {
      // ※ \nを追加しないこと
      return $1;
    })
    .replace( /<my-show-only>(.+?)<\/my-show-only>\n/gs , "")
    // --------------------
    //  第二階層(=子要素)
    // --------------------
    .replace(/<my-code-only-2>(.+?)<\/my-code-only-2>\n/gs, (match, $1) => {
      // ※ \nを追加しないこと
      return $1;
    })
    .replace( /<my-show-only-2>(.+?)<\/my-show-only-2>\n/gs , "")
    // ↑ ↑ ↑
    // ↑ ↑ ↑
    // ↑ ↑ ↑
    .replace(/<span class="highlight">(.+?)<\/span>/gs, (match, $1) => {
      return $1;
    })
    .replace( /\sclass="highlight"/g , "" ) // 順番: 1
    .replace( /\shighlight/g , "" ) // 順番: 2
    .replace( /highlight\s/g , "" ) // 順番: 2
    // ↓ ※ Webコンポーネントなのでビルド後直接HTMLでspanに変換される。
    .replace( /<my-hl>/g , "[hl]" )
    .replace( /<\/my-hl>/g , "[/hl]" );
}

const removeMyTag = (v: string) => {
  return v.replace( /\[hl\]/g , "" )
    .replace( /\[\/hl\]/g , "" );
}

const escapeHtml = (v: string) => {
  return v.replace(/&/g, "&amp;")
    // .replace(/@/g, "&#64;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

const addHtml = (v: string) => {
  // xxxx 反応しない return v.replace( /\[hl\]/g , '<my-hl>')
  // xxxx 反応しない   .replace( /\[\/hl\]/g , '</my-hl>');
  return v.replace( /\[hl\]/g , '<span class="hl">')
    .replace( /\[\/hl\]/g , '</span>');
}

setTimeout(() => {
  if (templateShowElement.value && templateCodeElement.value) {
    let tempCode = templateShowElement.value.innerHTML;
    tempCode = removeHtml(tempCode);
    tempCode = escapeHtml(tempCode);
    tempCode = addHtml(tempCode);
    tempCode = tempCode.replace(/\n/g, "<br>");
    templateCodeElement.value.innerHTML = tempCode;

    setTimeout(function() {
      const body = document.getElementsByTagName('body')[0];
      function replaceText(node: any) {
          // テキストノードなら変換
          if (node.nodeType === 3) { // TEXT_NODE
            node.textContent = node.textContent.replace(/&lt;/g, '<');
            node.textContent = node.textContent.replace(/&gt;/g, '>');
          }
          if (node.hasChildNodes()) {
            for (let child of node.childNodes) {
                replaceText(child);
            }
          }
      }
      replaceText(body);
    }, 0);
  }
}, 0)

// --------------------
//  style
// --------------------
function addCssToHead(cssString: string): void {
  // <style>タグを作成
  const styleTag = document.createElement('style');
  // styleTag.type = 'text/css';
  styleTag.innerHTML = cssString;

  // <head>に追加
  const head = document.head || document.getElementsByTagName('head')[0];
  head.appendChild(styleTag);
}

setTimeout(() => {
  if (styleShowElement.value && styleCodeElement.value) {
    let styleCode = styleShowElement.value.innerHTML;
    styleCode = removeHtml(styleCode);
    const styleCodeHeader = removeMyTag(styleCode);
    styleCode = escapeHtml(styleCode);
    styleCode = addHtml(styleCode);
    styleCode = styleCode.replace(/\n/g, "<br>");
    styleCodeElement.value.innerHTML = styleCode;
    addCssToHead(styleCodeHeader)
  }
}, 0)

// --------------------
//  script
// --------------------
setTimeout(() => {
  if (scriptShowElement.value && scriptCodeElement.value) {
    let scriptCode = scriptShowElement.value.innerHTML;
    scriptCode = removeHtml(scriptCode);
    scriptCode = addHtml(scriptCode);
    scriptCodeElement.value.innerHTML = scriptCode;
  }
}, 0)


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    id: props.id,
    class: _normalizeClass(["example-wrap", getXxxExampleClassName()])
  }, [
    (''!==props.id)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(Gaiyou, null, {
            title: _withCtx(() => [
              _createTextVNode(_toDisplayString(props.id) + " …… " + _toDisplayString(getTitle(props.id)), 1)
            ]),
            description: _withCtx(() => [
              _createElementVNode("div", {
                innerHTML: getDescription(props.id)
              }, null, 8, _hoisted_3)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(["example reference", {isnoexample: props.isnoexample}])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["prompt-wrap", {'mb-[-64px]': props.isnoshow, 'hidden': props.isnocode || props.isonlyshow}])
      }, [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "codeTitle", {}, () => [
            _cache[1] || (_cache[1] = _createTextVNode(" SFC "))
          ])
        ]),
        _createVNode(Code, null, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass(["sfcTag", {'hidden': props.isjscode}])
            }, "<template>", 2),
            _createElementVNode("div", {
              ref_key: "templateCodeElement",
              ref: templateCodeElement
            }, null, 512),
            _createElementVNode("span", {
              class: _normalizeClass(["sfcTag", {'hidden': props.isjscode}])
            }, _cache[2] || (_cache[2] = [
              _createTextVNode("</template>"),
              _createElementVNode("br", null, null, -1)
            ]), 2),
            _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("span", {
              class: _normalizeClass(["sfcTag", {'hidden': props.isjscode}])
            }, _cache[3] || (_cache[3] = [
              _createTextVNode("<script setup>"),
              _createElementVNode("br", null, null, -1)
            ]), 2),
            _createElementVNode("div", {
              ref_key: "scriptCodeElement",
              ref: scriptCodeElement
            }, null, 512),
            _createElementVNode("span", {
              class: _normalizeClass(["sfcTag", {'hidden': props.isjscode}])
            }, _cache[4] || (_cache[4] = [
              _createTextVNode("</script>"),
              _createElementVNode("br", null, null, -1)
            ]), 2),
            _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
            _createElementVNode("span", {
              class: _normalizeClass(["sfcTag", {'hidden': props.isjscode}])
            }, "<style>", 2),
            _createElementVNode("div", {
              ref_key: "styleCodeElement",
              ref: styleCodeElement
            }, null, 512),
            _createElementVNode("span", {
              class: _normalizeClass(["sfcTag", {'hidden': props.isjscode}])
            }, "</style>", 2)
          ]),
          _: 1
        })
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["siyorei_arrow", {hidden: props.isnoshow || props.isnocode || props.isonlyshow}])
      }, " ↓↓↓ ", 2),
      _createElementVNode("div", {
        class: _normalizeClass(["siyorei_browse", {pdf_next_page: props.pdfNextPageShow, highlightflg: highlight.value, hidden: props.isnoshow}])
      }, [
        _createElementVNode("h4", {
          class: _normalizeClass(["header4", {'hidden': props.isnobrowsertitle || props.isonlyshow}])
        }, [
          _renderSlot(_ctx.$slots, "taitoru", {}, () => [
            _cache[7] || (_cache[7] = _createTextVNode(" ブラウザ上の表示 "))
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            id: "checkbox",
            class: _normalizeClass({hidden: __props.isnobrowsershowcheckbox}),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((highlight).value = $event)),
            onChange: highlightToggle
          }, null, 34), [
            [_vModelCheckbox, highlight.value]
          ])
        ], 2),
        _createElementVNode("div", {
          ref_key: "templateShowElement",
          ref: templateShowElement
        }, [
          _renderSlot(_ctx.$slots, "template")
        ], 512)
      ], 2),
      _createElementVNode("div", {
        ref_key: "scriptShowElement",
        ref: scriptShowElement,
        class: "hidden"
      }, [
        _renderSlot(_ctx.$slots, "script")
      ], 512),
      _createElementVNode("div", {
        ref_key: "styleShowElement",
        ref: styleShowElement,
        class: "hidden"
      }, [
        _renderSlot(_ctx.$slots, "style")
      ], 512)
    ], 2)
  ], 10, _hoisted_1))
}
}

})