<template>
<div :id="props.id" class="example-wrap" :class="getXxxExampleClassName()">
  <div v-if="''!==props.id">
    <Gaiyou>
      <template #title>
        {{props.id}} …… {{getTitle(props.id)}}
      </template>
      <template #description>
        <div v-html="getDescription(props.id)"></div>
      </template>
    </Gaiyou>
  </div>
  <div class="example reference" :class="{isnoexample: props.isnoexample}">
    <div class="prompt-wrap" :class="{'mb-[-64px]': props.isnoshow, 'hidden': props.isnocode || props.isonlyshow}">
      <div class="prompt-header">
        <slot name="codeTitle">
          SFC
        </slot>
      </div>
      <!--
      <div class="prompt-content" dir="ltr">
        <code class="code !break-words hljs language-javascript"><span class="sfcTag" :class="{'hidden': props.isjscode}">&lt;template&gt;</span>
  <div ref="templateCodeElement"></div><span class="sfcTag" :class="{'hidden': props.isjscode}">&lt;/template&gt;<br /></span><br /><span class="sfcTag" :class="{'hidden': props.isjscode}">&lt;script setup&gt;<br /></span><div ref="scriptCodeElement"></div><span class="sfcTag" :class="{'hidden': props.isjscode}">&lt;/script&gt;<br /></span><br /><span class="sfcTag" :class="{'hidden': props.isjscode}">&lt;style&gt;</span>
  <div ref="styleCodeElement"></div><span class="sfcTag" :class="{'hidden': props.isjscode}">&lt;/style&gt;</span>
        </code>
      -->
        <Code><span class="sfcTag" :class="{'hidden': props.isjscode}">&lt;template&gt;</span>
  <div ref="templateCodeElement"></div><span class="sfcTag" :class="{'hidden': props.isjscode}">&lt;/template&gt;<br /></span><br /><span class="sfcTag" :class="{'hidden': props.isjscode}">&lt;script setup&gt;<br /></span><div ref="scriptCodeElement"></div><span class="sfcTag" :class="{'hidden': props.isjscode}">&lt;/script&gt;<br /></span><br /><span class="sfcTag" :class="{'hidden': props.isjscode}">&lt;style&gt;</span>
  <div ref="styleCodeElement"></div><span class="sfcTag" :class="{'hidden': props.isjscode}">&lt;/style&gt;</span>
        </Code>
      </div>
    <!--
    </div>
    -->
    <div class="siyorei_arrow" :class="{hidden: props.isnoshow || props.isnocode || props.isonlyshow}">
      ↓↓↓
    </div>
    <div class="siyorei_browse" :class="{pdf_next_page: props.pdfNextPageShow, highlightflg: highlight, hidden: props.isnoshow}">
      <h4 class="header4" :class="{'hidden': props.isnobrowsertitle || props.isonlyshow}">
        <slot name="taitoru">
          ブラウザ上の表示
        </slot>
        <input type="checkbox" id="checkbox" :class="{hidden: isnobrowsershowcheckbox}" v-model="highlight" @change="highlightToggle">
      </h4>
      <div ref="templateShowElement">
        <slot name="template"></slot>
      </div>
    </div>
    <div ref="scriptShowElement" class="hidden">
      <slot name="script"></slot>
    </div>
    <div ref="styleShowElement" class="hidden">
      <slot name="style"></slot>
    </div>
  </div>
</div>
</template>

<script setup lang="tsx">
import type { ListType, InListType } from "@/types/reference.type";
import { ref, computed, defineProps, PropType } from 'vue';
import type { Ref } from 'vue';
import { useStore } from 'vuex';
import Gaiyou from "@/components/example/GaiyouComponents.vue";
import Code from "@/components/CodeComponent.vue";

const store = useStore();

const props = defineProps({
  id: { type: String, default: "" },
  list: { type: Array as PropType<ListType[]>, default: () => [] },
  pdfNextPageShow: { type: Boolean, default: false },
  isnocode: { type: Boolean, default: false },
  isnoexample: { type: Boolean, default: false },
  isnoshow: { type: Boolean, default: false },
  isonlyshow: { type: Boolean, default: false },
  isnobrowsershowcheckbox: { type: Boolean, default: false },
  isnospacemargintop: { type: Boolean, default: false },
  isnobrowsertitle: { type: Boolean, default: false },
  isjscode: { type: Boolean, default: false },
});

function getXxxExampleClassName() {
  let className = ""
  if ("<"===props.id.substring(0, 1) && ">"===props.id.substring(props.id.length-1)) {
    className = props.id.replace(/<|>/g , "")+'-example'
  }
  if (props.isnospacemargintop) {
    className = className + " mt-[-30px]"
  }
  return className
}
// function getId(id: string) {
//   const l = props.list.find((o: ListType) => {
//     return o.list.find((obj: InListType) => {
//       if (obj.id===id) {
//         return o
//       }
//     })
//   }) 
//   const result = l!.list.find((obj: InListType) => {
//     if (obj.id === id) {
//       return obj.id
//     }
//   })
//   return result?.id
// }
function getTitle(id: string) {
  const l = props.list.find((o: ListType) => {
    return o.list.find((obj: InListType) => {
      if (obj.id===id) {
        return o
      }
    })
  }) 
  if (!l) {
    return "";
  }
  const result = l.list.find((o: InListType) => {
    if (o.id === id) {
      return o.title
    }
  })
  return result?.title ?? ""
}
function getDescription(id: string) {
  const l = props.list.find((o: ListType) => {
    return o.list.find((obj: InListType) => {
      if (obj.id===id) {
        return o
      }
    })
  }) 
  if (!l) {
    return "";
  }
  const result = l.list.find((o: InListType) => {
    if (o.id === id) {
      return o.description
    }
  })
  return result?.description ?? ""
}


const highlight = computed(() => store.getters.highlight);
const highlightToggle = () => {
  store.dispatch('highlightToggle');
}

const templateCodeElement: Ref<null | HTMLDivElement> = ref(null);
const templateShowElement: Ref<null | HTMLDivElement> = ref(null); // ★

const scriptCodeElement: Ref<null | HTMLDivElement> = ref(null);
const scriptShowElement: Ref<null | HTMLDivElement> = ref(null); // ★

const styleCodeElement: Ref<null | HTMLDivElement> = ref(null);
const styleShowElement: Ref<null | HTMLDivElement> = ref(null); // ★

// ● [xx]aaa[/xx]を削除
// . ... 任意の1文字、+ ... 直前のパターンの1回以上繰り返し
// ? ... 直前のパターンの0～1回繰り返し
// .replace( /\[hL\](.+?)\[\/hL\]/g , "" );

const removeHtml = (v: string) => {
  // const a = v
  //   .replace( /^<pre><pre>/s , "" )
  //   .replace( /<\/pre><\/pre>$/s , "" )
  //   // .replace( /^<pre>/s , "" )
  //   // .replace( /<\/pre>$/s , "" )
  // console.log(a)

  return v//.replace( /<!--(.+?)-->/g , "" )
    // やめた <pre data-v-****>にもマッチするように( .replace( /^<pre[^>]*>/s , "" ) )。
    .replace( / data-v-(.+?)=""/g , "" )
    .replace( /^<pre>/s , "" )
    .replace( /<\/pre>$/s , "" )
    // ※ 決まり事: コンポーネントの場合<pre class="base">で囲まないといけない。
    .replace(/<pre class="base">(.+?)<\/pre>\n/s, (match, $1) => {
      return $1;
    })

    // ↓ ↓ ↓
    // ↓ ↓ ↓
    // ↓ ↓ ↓
    // --------------------
    //  第一階層(親要素)
    // --------------------
    // ※ 入れ子に注意: my-code-xxxとmy-show-xxx-2に分けた。
    // ※ 決まり事: <my-code-only>タグの前にスペースを置かないこと。
    // ※ 決まり事: <my-code-only>タグの前にスペースを置かないこと。
    // ※ 決まり事: <my-code-only>タグの前にスペースを置かないこと。
    // s: ドット（.）が改行文字にも一致
    .replace(/<my-code-only>(.+?)<\/my-code-only>\n/gs, (match, $1) => {
      // ※ \nを追加しないこと
      return $1;
    })
    .replace( /<my-show-only>(.+?)<\/my-show-only>\n/gs , "")
    // --------------------
    //  第二階層(=子要素)
    // --------------------
    .replace(/<my-code-only-2>(.+?)<\/my-code-only-2>\n/gs, (match, $1) => {
      // ※ \nを追加しないこと
      return $1;
    })
    .replace( /<my-show-only-2>(.+?)<\/my-show-only-2>\n/gs , "")
    // ↑ ↑ ↑
    // ↑ ↑ ↑
    // ↑ ↑ ↑
    .replace(/<span class="highlight">(.+?)<\/span>/gs, (match, $1) => {
      return $1;
    })
    .replace( /\sclass="highlight"/g , "" ) // 順番: 1
    .replace( /\shighlight/g , "" ) // 順番: 2
    .replace( /highlight\s/g , "" ) // 順番: 2
    // ↓ ※ Webコンポーネントなのでビルド後直接HTMLでspanに変換される。
    .replace( /<my-hl>/g , "[hl]" )
    .replace( /<\/my-hl>/g , "[/hl]" );
}

const removeMyTag = (v: string) => {
  return v.replace( /\[hl\]/g , "" )
    .replace( /\[\/hl\]/g , "" );
}

const escapeHtml = (v: string) => {
  return v.replace(/&/g, "&amp;")
    // .replace(/@/g, "&#64;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
}

const addHtml = (v: string) => {
  // xxxx 反応しない return v.replace( /\[hl\]/g , '<my-hl>')
  // xxxx 反応しない   .replace( /\[\/hl\]/g , '</my-hl>');
  return v.replace( /\[hl\]/g , '<span class="hl">')
    .replace( /\[\/hl\]/g , '</span>');
}

setTimeout(() => {
  if (templateShowElement.value && templateCodeElement.value) {
    let tempCode = templateShowElement.value.innerHTML;
    tempCode = removeHtml(tempCode);
    tempCode = escapeHtml(tempCode);
    tempCode = addHtml(tempCode);
    tempCode = tempCode.replace(/\n/g, "<br>");
    templateCodeElement.value.innerHTML = tempCode;

    setTimeout(function() {
      const body = document.getElementsByTagName('body')[0];
      function replaceText(node: any) {
          // テキストノードなら変換
          if (node.nodeType === 3) { // TEXT_NODE
            node.textContent = node.textContent.replace(/&lt;/g, '<');
            node.textContent = node.textContent.replace(/&gt;/g, '>');
          }
          if (node.hasChildNodes()) {
            for (let child of node.childNodes) {
                replaceText(child);
            }
          }
      }
      replaceText(body);
    }, 0);
  }
}, 0)

// --------------------
//  style
// --------------------
function addCssToHead(cssString: string): void {
  // <style>タグを作成
  const styleTag = document.createElement('style');
  // styleTag.type = 'text/css';
  styleTag.innerHTML = cssString;

  // <head>に追加
  const head = document.head || document.getElementsByTagName('head')[0];
  head.appendChild(styleTag);
}

setTimeout(() => {
  if (styleShowElement.value && styleCodeElement.value) {
    let styleCode = styleShowElement.value.innerHTML;
    styleCode = removeHtml(styleCode);
    const styleCodeHeader = removeMyTag(styleCode);
    styleCode = escapeHtml(styleCode);
    styleCode = addHtml(styleCode);
    styleCode = styleCode.replace(/\n/g, "<br>");
    styleCodeElement.value.innerHTML = styleCode;
    addCssToHead(styleCodeHeader)
  }
}, 0)

// --------------------
//  script
// --------------------
setTimeout(() => {
  if (scriptShowElement.value && scriptCodeElement.value) {
    let scriptCode = scriptShowElement.value.innerHTML;
    scriptCode = removeHtml(scriptCode);
    scriptCode = addHtml(scriptCode);
    scriptCodeElement.value.innerHTML = scriptCode;
  }
}, 0)

</script>


<style scoped lang="scss">
// ※ preはこのコンポーネントの外から渡される。
// ※ このコンポーネントのscopedに記述するとpreにスタイルを適応することができない。
// ※  → 共通部分に記述。
// .siyorei_browse {
//   pre {
//     white-space: normal;
//   }
// }
// .example {
//   // xxxx .siyorei_browse {
//   // xxxx   // ※ 渡されたスロット内容は渡した側じゃないと見えない
//   // xxxx   pre {
//   // xxxx     white-space: normal;
//   // xxxx   }
//   // xxxx }
// }
</style>
