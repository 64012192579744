import { createStore } from 'vuex'

export default createStore({
  state: {
    highlight: false,
    count: 0,
  },
  getters: {
    highlight(state) {
      return state.highlight;
    },
    getCount(state) {
      return state.count;
    },
  },
  mutations: {
    highlightToggle(state) {
      state.highlight = !state.highlight;
    },
    highlightFalse(state) {
      state.highlight = false;
    },
    // ※ mutationsを呼んだ場合
    // increment(state, newCount) {
    increment(state) { state.count++; },
    // → store.commit('increment');
  },
  actions: {
    highlightToggle: ({ commit }) => commit('highlightToggle'),
    highlightFalse: ({ commit }) => commit('highlightFalse'),
    // → store.dispatch('highlightToggle');
    // increment: ({ commit }) => commit('increment'),
    // → store.dispatch('increment');
  },
  modules: {
  }
})
