<template>
<slot></slot>
</template>

<style lang="scss">
my-code-only {
  display: none;
}
my-code-only-2 {
  display: none;
}
</style>
