<template>
  <div class="main01 reference javascript">

    <h1 class="midasi text-xl">JavaScript リファレンス</h1>
    <Reference :list="list" />
    <div class="examples">

      <!-- --------------------
        alert
      -------------------- -->
      <ExampleTemp :id="'alert'" :list="list" :pdfNextPageShow="true" :isnobrowsertitle="true">
        <template #template>
<pre>
<div><my-code-only>
  &lt;button class="btn btn-white" @click="showAlert()"&gt;クリックするとアラートが表示されます。&lt;/button&gt;</my-code-only>
<my-show-only>
  <button class="btn btn-white" @click="showAlert()">クリックするとアラートが表示されます。</button>
</my-show-only>

</div>
</pre>
        </template>
        <template #script>
<pre>
{{showAlert0}}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        console
      -------------------- -->
      <ExampleTemp :id="'console'" :list="list" :pdfNextPageShow="true" :isnobrowsertitle="true">
        <template #template>
<pre>
<div>
  ブラウザの開発者ツールを開いて(Ctrl + Shift + i)、コンソール(Console)を見てください。
</div>
<div><my-code-only>
  &lt;button class="btn btn-white" @click="showConsole()"&gt;クリックするとコンソールに表示されます。&lt;/button&gt;
</my-code-only>
<my-show-only>
  <button class="btn btn-white" @click="showConsole()">クリックするとコンソールに表示されます。</button>
</my-show-only>
</div>
</pre>
        </template>
        <template #script>
<pre>
{{showConsole0}}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        await async
      -------------------- -->
      <ExampleTemp :id="'await/async'" :list="list" :pdfNextPageShow="true" :isnobrowsertitle="true">
        <template #template>
<pre>
<div class="awaitasync-sample">
  <div id="awaitasync-message"></div>
  <div><my-code-only>
    &lt;button class="btn btn-white" @click="showAwaitAsync()"&gt;クリックすると５秒後に処理が終了します。&lt;/button&gt;</my-code-only>
<my-show-only>
    <button class="btn btn-white" @click="showAwaitAsync()">クリックすると５秒後に処理が終了します。</button>
</my-show-only>

  </div>
  <div>
    <span>※ コンソールに処理される順番が表示されます。</span>
  </div>
</div>
</pre>
        </template>
        <template #script>
<pre>
{{awaitAsyncCode}}
</pre>
        </template>
        <template #style>
<pre>
.awaitasync-sample {
  #awaitasync-message {
    margin-bottom: 16px;
  }
  span {
    font-size: 12px;
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        document.createElement
      -------------------- -->
      <ExampleTemp :id="'document.createElement'" :list="list" :pdfNextPageShow="true" :isnobrowsertitle="true">
        <template #template>
<pre>
<div class="create-element-sample">
  <div>以下はJavaScriptコードから生成されたHTMLタグです。</div>
  <div class="new-p"></div>
  <div><my-code-only>
    &lt;button class="btn btn-white" @click="onClickCreateElement()"&gt;クリックすると★が増えていきます。&lt;/button&gt;</my-code-only>
<my-show-only>
  <button class="btn btn-white" @click="onClickCreateElement()">クリックすると★が増えていきます。</button>
</my-show-only>

  </div>
</div>
</pre>
        </template>
        <template #script>
<pre>
{{onClickCreateElement0}}
</pre>
        </template>
        <template #style>
<pre>
.create-element-sample {
  .new-p {
    background: white;
    margin: 15px auto;
    width: 200px;
    min-height: 50px;
    p {
      text-align: center;
    }
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        URL.createObjectURL
      -------------------- -->
      <ExampleTemp :id="'URL.createObjectURL'" :list="list" :pdfNextPageShow="true" :isnobrowsertitle="true">
        <template #template>
<pre>
<div class="create-object-url-sample">
  <div class="profile-container">
    <h3>プロフィール画像変更の例</h3>
    <!-- 現在のプロフィール画像 -->
    <div class="profile-image-container">
      <img id="profile-image" src="/profile.jpg" alt="プロフィール画像" class="profile-image">
    </div>
    <!-- 画像選択ボタン --><my-code-only>
    &lt;input type="file" id="image-upload" accept="image/*" @change="onChangeCreateObjectURL"&gt;</my-code-only>
<my-show-only>
    <input type="file" id="image-upload" accept="image/*" @change="onChangeCreateObjectURL" />
</my-show-only>

    <!-- 選択した画像の確認 -->
    <div id="file-name">{{profileObjectURL}}</div>
    <div class="flush-wrap">
      <button class="btn btn-white" @click="onClickFlushObjectURL">一時的なURLを解放する</button>
    </div>
  </div>
</div>
</pre>
        </template>
        <template #script>
<pre>
{{createObjectURLCode0}}
</pre>
        </template>
        <template #style>
<pre>
.create-object-url-sample {
  .profile-container {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    width: 100%;
    @media (min-width: 400px) {
      width: 300px;
    }
  }
  .profile-image-container {
    margin-bottom: 20px;
    text-align: center;
  }
  .profile-image {
    width: 100px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 10px;
  }
  input[type="file"] {
    font-size: 0.8rem;
    margin: 10px 0;
  }
  #file-name {
    color: #777;
    font-size: 14px;
  }
  .flush-wrap {
    margin-top: 16px;
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
        URL.revokeObjectURL
      -------------------- -->
      <div id="URL.revokeObjectURL" class="example-wrap pdf_next_page_no">
        <ExampleTemp :id="'URL.revokeObjectURL'" :list="list" :pdfNextPageShow="true" :isnoexample="true">
        </ExampleTemp>
      </div>

      <!-- --------------------
       
      -------------------- -->
      <!--
      <ExampleTemp :id="''" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
</pre>
        </template>
        <template #script>
<pre>
</pre>
        </template>
        <template #style>
<pre>
</pre>
        </template>
      </ExampleTemp>
      -->

      <PdfNextPage />
      <!-- --------------------
        ノート
      -------------------- -->
      <!--
      <div id="note" class="note">
        <h4 class="text-center">ノート</h4>
        <ul class="h2-list">
          <li>
            xxxとは
            <div>
            </div>
          </li>
        </ul>
      </div>
      -->

    </div><!-- .examples -->

</div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useStore } from 'vuex';
import Reference from "@/components/example/ReferenceComponents.vue";
import ExampleTemp from "@/components/example/ExampleTempComponents.vue";
import PdfNextPage from "@/components/PdfNextPage.vue";
// import Marker from "@/components/MarkerComponent.vue";

const store = useStore();
store.dispatch("highlightFalse");

//          <dl class="description-option">
const list = [
  {
    title: "関数",
    list: [
      {
        id: "alert",
        title: "画面にメッセージをポップアップで表示",
        description: "JavaScriptのalert()は、「画面にメッセージをポップアップで表示するための関数」です。ウェブページを操作しているときに、お知らせや注意書きを表示させるために使います。alert()は、小さなポップアップウィンドウを画面の中央に表示する関数です。その中にメッセージを表示して、ユーザーに伝えたいことを知らせることができます。たとえば、「このページを保存していません」とか、「確認してから進んでください」などのメッセージを表示するために使います。",
      }, {
        id: "console",
        title: "プログラムの中で情報の確認やデバッグ",
        description: "consoleは、プログラムの中で情報を確認したり、デバッグ（バグを見つけること）したりするために使うとても便利なツールです。consoleは、ブラウザの開発者ツールに出力するための特別なオブジェクトです。簡単に言うと、コンピュータの「メモ帳」みたいなもので、プログラムの動きを確認するためにメッセージを表示させることができます。consoleを使うと、プログラムがうまく動いているかどうかを簡単にチェックできます。これを使うことで、何が起きているのか、どこに問題があるのかを確認できます。", 
      }, {
        id: "await/async",
        title: "同期処理",
        description: "JavaScriptでは、何かの処理を順番に行うことが普通です。たとえば、1番目の仕事が終わってから、2番目の仕事を始める、というふうに処理が行われます。しかしインターネットからデータを取ってきたり、大きな計算をしたりするときには、少し時間がかかることがあります。そのため、実際の処理は順番通りにいかないものです。「待っている間に他のことをしたい」という場合に便利なのが、asyncとawaitという仕組みです。async は 「この関数は時間がかかるかもしれないことをする関数だよ」 という意味を持っていて、await は 「時間がかかることを待っている間、他のことをしてもいいよ」 という意味を持っています。"
      },
    ],
  }, {
    title: "documentオブジェクト",
    list: [
      {
        id: "document.createElement",
        title: "HTMLの新しいタグをJavaScriptで作るための関数",
        description: "document.createElementは、簡単に言うとページの中に新しい部品（要素）を作るための道具です。例えば、pタグを作って、その中に文字を入れ、ページに表示させることができます。これを使うと、最初からあるHTMLタグだけでなく、後から自分で作ったタグをページに追加できるので、動的に内容を変更したり、新しい情報を追加したりすることができるんです。",
      }
    ],
  }, {
    title: "URLオブジェクト",
    list: [
      {
        id: "URL.createObjectURL",
        title: "ブラウザ上で使うファイルのURLパスを生成",
        description: "URL.createObjectURLは、ブラウザ上でローカルのファイルを一時的にURL（インターネット上の住所みたいなもの）として扱うことができます。具体的に言うと、例えば自分のパソコンに保存している画像ファイルや動画ファイルを、ブラウザ内で「このファイルを使いたいよ！」という風に伝えるための特別なリンクを作ることができるんです。インターネット上のファイルは「URL」を使ってアクセスしますが、あなたのパソコンに保存されているファイルにはURLがありません。そこで、この「URL.createObjectURL」を使うことで、ブラウザがそのファイルを一時的に「ウェブ上で使えるURL」に変えてくれます。たとえばユーザ設定画面にあるプロフィール画像を変更する際、送信する前に選んだ画像を表示して確認できると親切です。",
      }, {
        id: "URL.revokeObjectURL",
        title: "URL.createObjectURLで作った一時的なURLを解放",
        description: `<p>URL.revokeObjectURLは、URL.createObjectURLで作った一時的なURLを無効にするために使います。URL.createObjectURL で、ローカルのファイルを一時的な「URL」に変えてブラウザに表示することができました。URL.revokeObjectURL は、そのURLがもう必要なくなったときに、ブラウザに「このURLはもう使わないから消してね！」と伝えるために使います。これを使うことで、ブラウザのメモリを無駄に使わないようにできます。例えば、もう表示した画像を消したり、ファイルのリンクが不要になった時にメモリを開放することができます。</p>
        <a class="a" href="#URL.createObjectURL">※ URL.createObjectURLで説明</a>
        `,
      },
    ],
  }
];


// const removeHtml = (v: string) => {
//   return v.replace(/<Marker>(.+?)<\/Marker>\n/s, (match, $1) => {
//     return "<span class='hl'>"+$1+"</span>";
//   })
// }


// --------------------
//  alert
// --------------------
const alertCode0 = `  alert("アラートが表示されました。");`

const showAlert0 = `function showAlert() {
${alertCode0}
}`;

function showAlert() {
  eval(alertCode0)
}

// --------------------
//  console
// --------------------
const consoleCode0 = `  // JavaScriptのコード
  console.log("これはコンソールに表示されるメッセージです！");

  // もう少し複雑な例
  const name = "山田太郎";
  console.log("こんにちは、" + name + "さん！");

  // 数字の計算結果を表示
  const num = 10 + 20;
  console.log("10 + 20 は " + num + " です。");

  // エラーは赤丸×で表示
  console.error("エラーが発生しました");`

const showConsole0 = `function showConsole() {
${consoleCode0}
}`

function showConsole() {
  eval(consoleCode0)
}

// --------------------
//  await async
// --------------------
// xxxx ※ awaitは上位層じゃないと使えない。
// xxxx function showAwaitAsync() {
// xxxx   eval(removeHtml(awaitAsyncCode))
// xxxx }

const awaitAsyncCode = `let asyncawaitTimer = 0;
function sleep(s: number) {
  return new Promise((resolve) => {
    const awaitasyncMessageElement = document.getElementById('awaitasync-message') as HTMLElement;

    const timerInc = 1000;
    const timeEnd = s*1000;
    const intervalId = setInterval(() => {
      if (null !== awaitasyncMessageElement) {
        awaitasyncMessageElement.textContent = "処理中です、ちょっと待ってね ... "+getAsyncAwaitSecond()+"秒";
      }
      if (0 === asyncawaitTimer) {
        console.log(2)
      }
      if (timeEnd === asyncawaitTimer) {
        if (null !== awaitasyncMessageElement) {
          awaitasyncMessageElement.textContent = "処理が完了しました。";
        }
        asyncawaitTimer = 0;
        clearInterval(intervalId)
        return resolve(true);
      }
      asyncawaitTimer += timerInc
    }, 1000)
  });
}
function getAsyncAwaitSecond(): string {
  return String(asyncawaitTimer/1000).replace(/[0-9a-zA-Z]/g, (char) =>
    String.fromCharCode(char.charCodeAt(0) + 0xFEE0)
  );
}
// asyncを使って、非同期処理を待つ関数
async function showAwaitAsync() {
  console.log(1)
  // awaitを使って、待っている間に他のことをしないようにする
  await sleep(5);
  console.log(3)
}`;

// ↓ ↓ ↓  上にコピペ
// ↓ ↓ ↓  上にコピペ
// ↓ ↓ ↓  上にコピペ
let asyncawaitTimer = 0;
function sleep(s: number) {
  return new Promise((resolve) => {
    const awaitasyncMessageElement = document.getElementById('awaitasync-message') as HTMLElement;

    const timerInc = 1000;
    const timeEnd = s*1000;
    const intervalId = setInterval(() => {
      if (null !== awaitasyncMessageElement) {
        awaitasyncMessageElement.textContent = "処理中です、ちょっと待ってね ... "+getAsyncAwaitSecond()+"秒";
      }
      if (0 === asyncawaitTimer) {
        console.log(2)
      }
      if (timeEnd === asyncawaitTimer) {
        if (null !== awaitasyncMessageElement) {
          awaitasyncMessageElement.textContent = "処理が完了しました。";
        }
        asyncawaitTimer = 0;
        clearInterval(intervalId)
        return resolve(true);
      }
      asyncawaitTimer += timerInc
    }, 1000)
  });
}
function getAsyncAwaitSecond(): string {
  return String(asyncawaitTimer/1000).replace(/[0-9a-zA-Z]/g, (char) =>
    String.fromCharCode(char.charCodeAt(0) + 0xFEE0)
  );
}
// asyncを使って、非同期処理を待つ関数
async function showAwaitAsync() {
  console.log(1)
  // awaitを使って、待っている間に他のことをしないようにする
  await sleep(5);
  console.log(3)
}
// ↑ ↑ ↑ 上にコピペ
// ↑ ↑ ↑ 上にコピペ
// ↑ ↑ ↑ 上にコピペ

// --------------------
//  document.createElement
// --------------------
const createElementCode0 = `  // 新しいpタグを作る
  const newParagraph = document.createElement('p');
  // pタグの中に文字を入れる
  newParagraph.textContent = '★';
  // class="new-p"要素を取得
  const element = document.querySelector('.create-element-sample .new-p');
  // 作ったpタグを、id="new-p"要素に追加する
  if (null!==element) {
    element.appendChild(newParagraph);
  }`
const onClickCreateElement0 = `function onClickCreateElement() {
${createElementCode0}
}`

function onClickCreateElement() {
  eval(createElementCode0)
}

// --------------------
//   createObjectURL
// --------------------
//  document.getElementById('image-upload').addEventListener('change', function(event) {
const createObjectURLCode0 = `import { ref } from "vue";
const profileObjectURL = ref<string>("選択された画像のURLはありません、画像を選択してください。");
const profileObjectURLBackup = ref<null>(null);
function setProfileImage(obj) {
  const profileImageElement = document.getElementById('profile-image') as HTMLImageElement;
  if (null !== profileImageElement) {
    profileImageElement.src = obj;
    profileObjectURL.value = "生成されたブラウザ上のURL: "+obj;
  }
}
function onChangeCreateObjectURL(event: Event) {
  // --------------------
  // プロフィール画像を選択したときの裏側
  // --------------------
  if (event.target instanceof HTMLInputElement && null!==event.target.files) {
    const file: File = event.target.files[0]; // ユーザーが選択したファイル
    if (file) {
      // createObjectURLを使って、一時的なURLを作成
      const objectURL = URL.createObjectURL(file);
      // console.log(objectURL)
      profileObjectURLBackup.value = objectURL;
      // そのURLを画像のsrcに設定して、画像を表示
      setProfileImage(objectURL);
    }
  }
}
function onClickFlushObjectURL() {
  // 使用したURLを解放
  URL.revokeObjectURL(profileObjectURLBackup.value);
  // 再度設定すると画像設定失敗する。
  setProfileImage(profileObjectURLBackup.value);
  profileObjectURL.value = "選択された画像のURLはありません、画像を選択してください。";
}`;
// ↓ ↓ ↓  上にコピペ
// ↓ ↓ ↓  上にコピペ
// ↓ ↓ ↓  上にコピペ
// import { ref } from "vue";
const profileObjectURL = ref<string>("選択された画像のURLはありません、画像を選択してください。");
const profileObjectURLBackup = ref<null>(null);
function setProfileImage(obj) {
  const profileImageElement = document.getElementById('profile-image') as HTMLImageElement;
  if (null !== profileImageElement) {
    profileImageElement.src = obj;
    profileObjectURL.value = "生成されたブラウザ上のURL: "+obj;
  }
}
function onChangeCreateObjectURL(event: Event) {
  // --------------------
  // プロフィール画像を選択したときの裏側
  // --------------------
  if (event.target instanceof HTMLInputElement && null!==event.target.files) {
    const file: File = event.target.files[0]; // ユーザーが選択したファイル
    if (file) {
      // createObjectURLを使って、一時的なURLを作成
      const objectURL = URL.createObjectURL(file);
      // console.log(objectURL)
      profileObjectURLBackup.value = objectURL;
      // そのURLを画像のsrcに設定して、画像を表示
      setProfileImage(objectURL);
    }
  }
}
function onClickFlushObjectURL() {
  // 使用したURLを解放
  URL.revokeObjectURL(profileObjectURLBackup.value);
  // 再度設定すると画像設定失敗する。
  setProfileImage(profileObjectURLBackup.value);
  profileObjectURL.value = "選択された画像のURLはありません、画像を選択してください。";
}
// ↑ ↑ ↑ 上にコピペ
// ↑ ↑ ↑ 上にコピペ
// ↑ ↑ ↑ 上にコピペ
</script>