import { createApp, defineCustomElement } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'

// import 'bootstrap-icons/font/bootstrap-icons.css'


// --------------------
//  my-span-hl
// --------------------
import MyHlElement from '@/components/myhtmltag/MyHlElement.vue';

// VueコンポーネントをWebコンポーネントに変換
const myHlElement = defineCustomElement(MyHlElement);

// Webコンポーネントとして登録
// 通常のHTML要素とは異なりダッシュが使われている名前( kebab-case )である必要がある。
customElements.define("my-hl", myHlElement);

// --------------------
//  my-code-only
// --------------------
import MyCodeOnlyElement from '@/components/myhtmltag/MyCodeOnlyElement.vue';
const myCodeOnlyElement = defineCustomElement(MyCodeOnlyElement);
customElements.define("my-code-only", myCodeOnlyElement);

// --------------------
//  my-show-only
// --------------------
import MyShowOnlyElement from '@/components/myhtmltag/MyShowOnlyElement.vue';
const myShowOnlyElement = defineCustomElement(MyShowOnlyElement);
customElements.define("my-show-only", myShowOnlyElement);

// --------------------
//  my-code-only-2
// --------------------
const myCodeOnly2Element = defineCustomElement(MyCodeOnlyElement);
customElements.define("my-code-only-2", myCodeOnly2Element);

// --------------------
//  my-show-only-2
// --------------------
const myShowOnly2Element = defineCustomElement(MyShowOnlyElement);
customElements.define("my-show-only-2", myShowOnly2Element);

// --------------------
// tailwind css
// --------------------
import './index.css'
// --------------------
// my scss
// --------------------
import './styles.scss'

loadFonts()

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app')
