<template>
  <div v-if="presentFromParams">
    <div>(パラメータ経由で) 太郎からもらったプレゼント：</div>
    <div>{{presentFromParams}}</div>
  </div>
  <div v-if="presentFromQuery">
    <div>(クエリ経由で) 太郎からもらったプレゼント：</div>
    <div>{{presentFromQuery}}</div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
const route = useRoute();

const presentFromParams = ref(route.params.present);
const presentFromQuery = ref(route.query.present);
</script>

<style scoped>
</style>