import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main01 reference html" }
const _hoisted_2 = { class: "examples" }
const _hoisted_3 = { lang: "en-US" }
const _hoisted_4 = { class: "hr-sample" }
const _hoisted_5 = { class: "py-4 highlight" }
const _hoisted_6 = { class: "p-sample" }
const _hoisted_7 = { class: "hx-sample" }
const _hoisted_8 = { class: "table-sample" }
const _hoisted_9 = {
  id: "<tbody>",
  class: "example-wrap pdf_next_page_no"
}
const _hoisted_10 = {
  id: "<td>",
  class: "example-wrap pdf_next_page_no"
}
const _hoisted_11 = {
  id: "<th>",
  class: "example-wrap pdf_next_page_no"
}
const _hoisted_12 = {
  id: "<thead>",
  class: "example-wrap pdf_next_page_no"
}
const _hoisted_13 = {
  id: "<tr>",
  class: "example-wrap pdf_next_page_no"
}
const _hoisted_14 = { class: "form-sample" }
const _hoisted_15 = { class: "button-sample" }
const _hoisted_16 = { class: "btn-wrap" }
const _hoisted_17 = { class: "btn-wrap" }
const _hoisted_18 = ["disabled"]
const _hoisted_19 = { class: "input-sample" }
const _hoisted_20 = { class: "label-sample" }
const _hoisted_21 = { class: "option-sample" }
const _hoisted_22 = {
  id: "option-color",
  name: "option-color"
}
const _hoisted_23 = { class: "a-sample" }
const _hoisted_24 = { class: "span-sample" }

import { ref } from "vue";
import { useStore } from "vuex";
import Reference from "@/components/example/ReferenceComponents.vue";
import ExampleTemp from "@/components/example/ExampleTempComponents.vue";
import Marker from "@/components/MarkerComponent.vue";
import type { ListType } from "@/types/reference.type";


export default /*@__PURE__*/_defineComponent({
  __name: 'HtmlComponent',
  setup(__props) {

const store = useStore();
store.dispatch("highlightFalse");

// --------------------
//  input
// --------------------
const inputMyName = ref("");

const list: ListType[] = [
  {
    title: "グルーピング要素",
    list: [
      {
        id: "<div>",
        title: "（特別な意味がない箱）ボックス要素",
        description: "div要素は、特別な意味を付加せず子要素をまとめるコンテナ要素としてスタイリング目的に使用したり、 セクション内の複数の段落をラップしてまとめて意味付けする場合などに役立ちます。",
      }, {
        id: "<hr>",
        title: "水平線",
        description: "文書内でテーマの変化を示す水平線を挿入するために使用されます。別トピックに移行する箇所や物語の場面転換など、セクションの区切りや内容の分割を視覚的に表現するのに役立ちます。セマンティックな意味を持ち、内容のテーマの変化を示すために使用されることが推奨されています。",
      }, {
        id: "<p>",
        title: "段落（パラグラフ）",
        description: "HTMLのpタグは、「段落」を作るためのタグです。段落というのは、文章を区切って読みやすくするためのものです。例えば、手紙や本を読むときに、改行して段落を分けるように、ウェブページでも文章を読みやすくするために段落を分けます。pタグを使うと、文章をきれいに分けて表示することができ、段落ごとに自動で少しのスペース（空き）ができます。",
      },
    ],
  }, {
    title: "セクション",
    list: [
      {
        id: "<h1>・<h2>・<h3>・<h4>・<h5>・<h6>",
        title: "見出し",
        description: "数値が小さいほど大きい文字・大切な意味の見出しになります。",
      }
    ],
  }, {
    title: "テーブル",
    list: [
      {
        id: "<table>",
        title: "テーブル（表）を作成",
        description: "HTMLのtableタグは、「表（テーブル）」を作るためのタグです。表は、データや情報を整理して見やすく並べるために使います。例えば、成績表や、予定表、商品のリストなど、行と列を使ってデータを整理する時に使います。tableタグを使うと、情報を行（横）と列（縦）に分けて表示することができます。",
      }, {
        id: "<tbody>",
        title: "(テーブルの中の) データが入る場所を区切る",
        description: `HTMLのtbodyタグは、HTMLの表（テーブル）の中で、「データの部分」を区切るためのタグです。表は、通常、見出しやデータなどが含まれているのですが、tbodyタグを使うことで、データの部分を明確に分けることができます。これにより、表がもっと整理され、見やすくなるだけでなく、特に大きな表を作るときに便利です。tbodyタグは、通常、表の中のデータ行（つまり、trタグが入る場所）を囲むために使います。theadタグとtfootタグが、表の見出し部分やフッター（最後の部分）を担当するのに対し、tbodyタグはその間のメインのデータ部分を担当します。
        <a class="a" href="#<table>">※ サンプルはtableで確認</a>`
      }, {
        id: "<td>",
        title: "(テーブルの中の) データを入れる場所",
        description: `HTMLのtdタグは、表（テーブル）の中に「データを入れる場所」を作るためのタグです。ウェブページでテーブルを使って情報を整理する際に、このtdタグを使います。tdタグは、テーブルの中で「1つのセル」を作るためのタグです。テーブルは縦と横に並んだ線で囲まれた表のことですが、その表の中の1つ1つの「箱」のことを「セル」と言います。tdタグはそのセルにデータや文字を入れるために使います。テーブルを作るためには、まずtableタグで表を作り、その中に行を作るtrタグ、そしてその行の中にセルを作るtdタグを使います。
        <a class="a" href="#<table>">※ サンプルはtableで確認</a>`
      }, {
        id: "<th>",
        title: "(テーブルの中の) 見出しセル",
        description: `HTMLのthタグは、HTMLで作るテーブル（表）の中で、「見出し（ヘッダー）セル」を作るためのタグです。表には、データがたくさん並んでいますが、そのデータが何を意味しているのかを示すために「見出し」が必要です。この見出しを作るためにthタグを使います。thタグは、テーブルの中で「見出しセル」を作るためのタグです。通常、thタグは表の最初の行（見出しの行）や最初の列に使われます。見出しの部分にthタグを使うと、文字が太くなったり、中央に揃ったりして、データが何を意味しているのかがわかりやすくなります。thタグは、tableタグの中で、見出しを作りたいセルに使います。例えば、表の一番上の行に「名前」や「年齢」などの見出しを作り、その下にデータを並べることがよくあります。
        <a class="a" href="#<table>">※ サンプルはtableで確認</a>`,
      }, {
        id: "<thead>",
        title: "(テーブルの中の) 見出しの部分をまとめる",
        description: `HTMLのtheadタグは、HTMLで作る表（テーブル）の中で、「見出しの部分」をまとめるためのタグです。テーブルには「見出し」や「データ」などが含まれていますが、theadタグを使うと、テーブルの中で見出し部分がどこから始まるのかをはっきり示すことができます。 theadタグは、テーブルの中で「見出しの行」をまとめるためのタグです。通常、テーブルには複数の行がありますが、その中でも「見出しの行」は一番上にあり、他の行とは区別して表示されます。theadタグを使うと、見出し部分をきちんとグループ化することができ、特に大きな表では見やすく、整理された印象を与えることができます。theadタグは、trタグ（行）とその中のthタグ（見出しセル）を入れます。
        <a class="a" href="#<table>">※ サンプルはtableで確認</a>`,
      }, {
        id: "<tr>",
        title: "(テーブルの中の) 1つの行",
        description: `HTMLのtrタグは、HTMLで作るテーブル（表）の中で、「1つの行（行列の横の並び）」を作るためのタグです。テーブルは、縦（列）と横（行）でデータを並べますが、trタグはその「横」の並びを作るために使います。たとえば、名前や年齢、商品名などの情報を横に並べるときに、trタグで行を作ります。1行ごとにtrタグを使い、その中にデータ（tdタグ）や見出し（thタグ）を並べます。trタグは、テーブルの中に何行も作れます。
        <a class="a" href="#<table>">※ サンプルはtableで確認</a>`,
      }
    ],
  }, {
    title: "フォーム要素",
    list: [
      {
       id: "<button>",
        title: "ボタン",
        description: "<button>タグを記述すると、ボタンが表示されます。",
      }, {
        id: "<datalist>",
        title: "入力候補となるデータリスト",
        description: "HTMLのdatalistタグは、入力フォームで「選べる候補（選択肢）」を表示するためのタグです。これは、ユーザーが文字を入力し始めたときに、その入力にぴったり合う候補を表示して、選びやすくするために使います。例えば、名前や住所などを入力するフォームがあるとき、すでに入力されたことのある項目や予測される項目を選択肢として表示することで、ユーザーが早く正しく入力できるようにするための便利なタグです。",
      }, {
        id: "<form>",
        title: "入力・送信フォーム",
        description: "HTMLのformタグは、ウェブページで「情報を入力して送信するためのフォーム」を作るためのタグです。このタグを使うと、名前やメールアドレス、メッセージなど、ユーザーが情報を入力できるようになります。そして、入力された情報を送ることができるようになります。例えば、あなたがウェブサイトに名前やメールアドレスを入力して「送信」ボタンを押したとき、その情報がウェブサイトのサーバーに送られる仕組みを作るのがformタグです。", 
      }, {
        id: "<input>",
        title: "細長い入力欄",
        description: "フォーム入力欄を表す際に使用します。input要素は、type属性の値によって様々なフォームコントロールに変化します。",
      }, {
        id: "<label>",
        title: "ラベル",
        description: "フォーム入力欄のキャプションを表す。フォームの各入力欄となるユーザーインターフェイスのキャプションを表します。 label要素で指定するキャプションと、フォームの各入力欄を関連付けるには、2通りの方法があります。1つ目の方法は、label要素のfor属性の値と、 input要素、select要素、textarea要素などのフォーム部品のid名を同じものにする方法です。 label要素のfor属性の値とフォーム部品のid名が一致することで、 そのキャプションがどのフォーム入力欄のものなのかを関連付けます。2つ目の方法は、label要素自体の中にフォーム部品を配置することによって、 同じlabel要素内に記述されたキャプションとフォーム入力欄を関連付けます。2つの使用例で、キャプションとフォーム入力欄を関連付ける方法を2つ示しました。 どちらの場合にも、キャプションのテキスト部分をクリック（タップ）すると、関連するチェックボックスがcheckedの状態になります。 この挙動から、テキスト部分とチェックボックスが関連付けられていることが確認できます。"
      }, {
        id: "<option>",
        title: "セレクトボックスや入力候補リストの選択肢",
        description: "HTMLのoptionタグは、「選択肢」を作るためのタグです。主にselectタグと一緒に使われ、リストから1つの項目を選ぶためのアイテムを表示します。たとえば、ドロップダウンメニュー（選択肢が縦に並んだリスト）を作るときに使います。optionタグを使うと、ユーザーがリストの中から好きな項目を選べるようにすることができます。通常、optionタグはselectタグの中に書きます。selectタグは「選ぶリスト」を作るためのもので、その中にいくつかのoptionタグを入れることで、リストの中から1つを選ぶことができるようになります。",
      },
    ],
  }, {
    title: "テキストレベル要素",
    list: [
      {
        id: "<a>",
        title: "リンク(別のページに飛ぶ)",
        description: "HTMLのaタグは、「リンクを作るためのタグ」です。ウェブページの中で、別のページやサイト、あるいは同じページの別の場所に飛ぶことができるリンクを作るために使います。インターネットでどこか別のページに移動するとき、青い文字が下線付きで表示されている部分がaタグを使ったリンクになります。",
      }, {
        id: "<br>",
        title: "改行",
        description: "HTMLのbrタグは「改行」を意味するタグで、テキスト内に改行を挿入するために使用します。brタグは自己終了タグ（閉じタグが不要）で、単独で使います。これにより、次のテキストが新しい行に表示されます。",
      }, {
        id: "<i>",
        title: "斜体",
        description: "斜体にする効果があります。書籍のタイトルや外国語の単語を示す際に使用することが一般的です。強調や異なる声調を示すために用いられます。あまり使用頻度の高くないタグですが、「心の中の言葉」「ことわざ」「動植物の学名」など、他と区別したいときにも使われます。iタグは、アイコンフォントを使用する際にもよく使われます。※ ブラウザによってはイタリック体のスタイルがデフォルトではない(装飾されていない)ことがあります。",
      }, {
        id: "<span>",
        title: "インライン要素を作成(主にテキストの一部にスタイルを適用)",
        description: "spanタグは、HTMLの中で 「小さな範囲を指定するためのタグ」 です。これは、文の中の一部だけを特別に変更したり、他の部分とは違うスタイルをつけたりするときに使います。たとえば、文章の中で一部分だけ色を変えたり、太字にしたりする場合に便利です。",
      }
    ],
  }
];

// --------------------
//  Button script
// --------------------
const buttonCnt = ref(0);
function buttonAddFn() {
  buttonCnt.value++;
}
function buttonMinusFn() {
  if (buttonCnt.value <= 0) {
    return;
  }
  buttonCnt.value--;
}
function isDisabledButton() {
  return buttonCnt.value <= 0
}

// --------------------
//  
// --------------------

return (_ctx: any,_cache: any) => {
  const _component_my_show_only = _resolveComponent("my-show-only")!
  const _component_my_code_only = _resolveComponent("my-code-only")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[208] || (_cache[208] = _createElementVNode("h1", { class: "midasi text-xl" }, "HTMLリファレンス", -1)),
    _createVNode(Reference, { list: list }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(ExampleTemp, {
        id: '<div>',
        list: list,
        pdfNextPageShow: true
      }, {
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[12] || (_cache[12] = _createTextVNode("")),
            _createElementVNode("div", _hoisted_3, [
              _cache[4] || (_cache[4] = _createTextVNode("\n  ")),
              _cache[5] || (_cache[5] = _createElementVNode("h3", null, "The Door to Tomorrow", -1)),
              _cache[6] || (_cache[6] = _createTextVNode("\n  ")),
              _cache[7] || (_cache[7] = _createElementVNode("p", null, "\n    The morning breeze gently blows, A new day begins today.  The tears and troubles of yesterday are now in the past, A new opportunity is waiting for you.\n  ", -1)),
              _cache[8] || (_cache[8] = _createTextVNode("\n")),
              _createVNode(Marker, null, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("  "),
                  _createElementVNode("div", {
                    lang: "en-GB",
                    class: "highlight"
                  }, [
                    _createTextVNode("\n    "),
                    _createElementVNode("p", null, "\n      No matter how small the step, The courage to take it will change your future.  Even if the road is steep, You can overcome it. Believe in yourself and move forward.\n    "),
                    _createTextVNode("\n  ")
                  ], -1)
                ])),
                _: 1
              }),
              _cache[9] || (_cache[9] = _createTextVNode("\n  ")),
              _cache[10] || (_cache[10] = _createElementVNode("p", null, "\n    No matter the time, you are not alone.  Someone is by your side, cheering you on.  Open your heart, hold onto hope, And together, let’s open the door to tomorrow.\n  ", -1)),
              _cache[11] || (_cache[11] = _createTextVNode("\n"))
            ]),
            _cache[13] || (_cache[13] = _createTextVNode("\n"))
          ])
        ]),
        _: 1
      }),
      _createVNode(ExampleTemp, {
        id: '<hr>',
        list: list,
        pdfNextPageShow: true
      }, {
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[27] || (_cache[27] = _createTextVNode("")),
            _createElementVNode("div", _hoisted_4, [
              _cache[17] || (_cache[17] = _createTextVNode("\n  ")),
              _cache[18] || (_cache[18] = _createElementVNode("h3", null, "テーマA", -1)),
              _cache[19] || (_cache[19] = _createTextVNode("\n  ")),
              _cache[20] || (_cache[20] = _createElementVNode("div", null, "このテーマAの内容です。", -1)),
              _cache[21] || (_cache[21] = _createTextVNode("\n  ")),
              _createElementVNode("div", _hoisted_5, [
                _cache[15] || (_cache[15] = _createTextVNode("\n    ")),
                _createVNode(Marker, null, {
                  default: _withCtx(() => _cache[14] || (_cache[14] = [
                    _createElementVNode("hr", null, null, -1)
                  ])),
                  _: 1
                }),
                _cache[16] || (_cache[16] = _createTextVNode("\n  "))
              ]),
              _cache[22] || (_cache[22] = _createTextVNode("\n  ")),
              _cache[23] || (_cache[23] = _createElementVNode("h3", null, "テーマB", -1)),
              _cache[24] || (_cache[24] = _createTextVNode("\n  ")),
              _cache[25] || (_cache[25] = _createElementVNode("div", null, "次のテーマBの内容です。", -1)),
              _cache[26] || (_cache[26] = _createTextVNode("\n"))
            ]),
            _cache[28] || (_cache[28] = _createTextVNode("\n"))
          ])
        ]),
        style: _withCtx(() => _cache[29] || (_cache[29] = [
          _createElementVNode("pre", null, ".hr-sample {\n  .py-4 {\n    padding-top: 1rem; /* 16px */\n    padding-bottom: 1rem; /* 16px */\n  }\n}\n", -1)
        ])),
        _: 1
      }),
      _createVNode(ExampleTemp, {
        id: '<p>',
        list: list,
        pdfNextPageShow: true
      }, {
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[38] || (_cache[38] = _createTextVNode("")),
            _createElementVNode("div", _hoisted_6, [
              _cache[33] || (_cache[33] = _createTextVNode("\n  ")),
              _cache[34] || (_cache[34] = _createElementVNode("h3", null, "段落の例", -1)),
              _createVNode(Marker, null, {
                default: _withCtx(() => _cache[30] || (_cache[30] = [
                  _createTextVNode("\n  "),
                  _createElementVNode("p", { class: "highlight" }, "\n    これは最初の段落です。段落は文章を分けるために使います。\n  ", -1)
                ])),
                _: 1
              }),
              _cache[35] || (_cache[35] = _createTextVNode("\n")),
              _createVNode(Marker, null, {
                default: _withCtx(() => _cache[31] || (_cache[31] = [
                  _createTextVNode("  "),
                  _createElementVNode("p", { class: "highlight" }, "\n    これは二番目の段落です。段落ごとにスペースができて、読みやすくなります。\n  ", -1)
                ])),
                _: 1
              }),
              _cache[36] || (_cache[36] = _createTextVNode("\n")),
              _createVNode(Marker, null, {
                default: _withCtx(() => _cache[32] || (_cache[32] = [
                  _createTextVNode("  "),
                  _createElementVNode("p", { class: "highlight" }, "\n    これは三番目の段落です。こうして段落を分けると、文章がすっきりして見やすくなります。\n  ", -1)
                ])),
                _: 1
              }),
              _cache[37] || (_cache[37] = _createTextVNode("\n"))
            ]),
            _cache[39] || (_cache[39] = _createTextVNode("\n"))
          ])
        ]),
        _: 1
      }),
      _createVNode(ExampleTemp, {
        id: '<h1>・<h2>・<h3>・<h4>・<h5>・<h6>',
        list: list,
        pdfNextPageShow: true
      }, {
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[53] || (_cache[53] = _createTextVNode("")),
            _createElementVNode("div", _hoisted_7, [
              _cache[46] || (_cache[46] = _createTextVNode("\n  ")),
              _createVNode(Marker, null, {
                default: _withCtx(() => _cache[40] || (_cache[40] = [
                  _createElementVNode("h1", null, [
                    _createElementVNode("span", { class: "highlight" }, "見出し1")
                  ], -1)
                ])),
                _: 1
              }),
              _cache[47] || (_cache[47] = _createTextVNode("\n  ")),
              _createVNode(Marker, null, {
                default: _withCtx(() => _cache[41] || (_cache[41] = [
                  _createElementVNode("h2", null, [
                    _createElementVNode("span", { class: "highlight" }, "見出し2")
                  ], -1)
                ])),
                _: 1
              }),
              _cache[48] || (_cache[48] = _createTextVNode("\n  ")),
              _createVNode(Marker, null, {
                default: _withCtx(() => _cache[42] || (_cache[42] = [
                  _createElementVNode("h3", null, [
                    _createElementVNode("span", { class: "highlight" }, "見出し3")
                  ], -1)
                ])),
                _: 1
              }),
              _cache[49] || (_cache[49] = _createTextVNode("\n  ")),
              _createVNode(Marker, null, {
                default: _withCtx(() => _cache[43] || (_cache[43] = [
                  _createElementVNode("h4", null, [
                    _createElementVNode("span", { class: "highlight" }, "見出し4")
                  ], -1)
                ])),
                _: 1
              }),
              _cache[50] || (_cache[50] = _createTextVNode("\n  ")),
              _createVNode(Marker, null, {
                default: _withCtx(() => _cache[44] || (_cache[44] = [
                  _createElementVNode("h5", null, [
                    _createElementVNode("span", { class: "highlight" }, "見出し5")
                  ], -1)
                ])),
                _: 1
              }),
              _cache[51] || (_cache[51] = _createTextVNode("\n  ")),
              _createVNode(Marker, null, {
                default: _withCtx(() => _cache[45] || (_cache[45] = [
                  _createElementVNode("h6", null, [
                    _createElementVNode("span", { class: "highlight" }, "見出し6")
                  ], -1)
                ])),
                _: 1
              }),
              _cache[52] || (_cache[52] = _createTextVNode("\n"))
            ]),
            _cache[54] || (_cache[54] = _createTextVNode("\n"))
          ])
        ]),
        _: 1
      }),
      _createVNode(ExampleTemp, {
        id: '<table>',
        list: list,
        pdfNextPageShow: true
      }, {
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[60] || (_cache[60] = _createTextVNode("")),
            _createElementVNode("div", _hoisted_8, [
              _cache[56] || (_cache[56] = _createTextVNode("\n  ")),
              _cache[57] || (_cache[57] = _createElementVNode("h3", null, "成績表", -1)),
              _cache[58] || (_cache[58] = _createTextVNode("\n")),
              _createVNode(Marker, null, {
                default: _withCtx(() => _cache[55] || (_cache[55] = [
                  _createTextVNode("  "),
                  _createElementVNode("table", {
                    class: "highlight",
                    border: "1"
                  }, [
                    _createTextVNode("\n    "),
                    _createElementVNode("thead", null, [
                      _createTextVNode("\n      "),
                      _createElementVNode("tr", null, [
                        _createTextVNode("\n        "),
                        _createElementVNode("th", null, "名前"),
                        _createTextVNode("\n        "),
                        _createElementVNode("th", null, "数学"),
                        _createTextVNode("\n        "),
                        _createElementVNode("th", null, "英語"),
                        _createTextVNode("\n        "),
                        _createElementVNode("th", null, "理科"),
                        _createTextVNode("\n      ")
                      ]),
                      _createTextVNode("\n    ")
                    ]),
                    _createTextVNode("\n    "),
                    _createElementVNode("tbody", null, [
                      _createTextVNode("\n      "),
                      _createElementVNode("tr", null, [
                        _createTextVNode("\n        "),
                        _createElementVNode("td", null, "佐藤太郎"),
                        _createTextVNode("\n        "),
                        _createElementVNode("td", null, "80"),
                        _createTextVNode("\n        "),
                        _createElementVNode("td", null, "75"),
                        _createTextVNode("\n        "),
                        _createElementVNode("td", null, "90"),
                        _createTextVNode("\n      ")
                      ]),
                      _createTextVNode("\n      "),
                      _createElementVNode("tr", null, [
                        _createTextVNode("\n        "),
                        _createElementVNode("td", null, "鈴木花子"),
                        _createTextVNode("\n        "),
                        _createElementVNode("td", null, "85"),
                        _createTextVNode("\n        "),
                        _createElementVNode("td", null, "80"),
                        _createTextVNode("\n        "),
                        _createElementVNode("td", null, "95"),
                        _createTextVNode("\n      ")
                      ]),
                      _createTextVNode("\n      "),
                      _createElementVNode("tr", null, [
                        _createTextVNode("\n        "),
                        _createElementVNode("td", null, "山田一郎"),
                        _createTextVNode("\n        "),
                        _createElementVNode("td", null, "70"),
                        _createTextVNode("\n        "),
                        _createElementVNode("td", null, "60"),
                        _createTextVNode("\n        "),
                        _createElementVNode("td", null, "80"),
                        _createTextVNode("\n      ")
                      ]),
                      _createTextVNode("\n    ")
                    ]),
                    _createTextVNode("\n  ")
                  ], -1)
                ])),
                _: 1
              }),
              _cache[59] || (_cache[59] = _createTextVNode("\n"))
            ]),
            _cache[61] || (_cache[61] = _createTextVNode("\n"))
          ])
        ]),
        script: _withCtx(() => _cache[62] || (_cache[62] = [
          _createElementVNode("pre", null, "", -1)
        ])),
        style: _withCtx(() => _cache[63] || (_cache[63] = [
          _createElementVNode("pre", null, ".table-sample {\n  table {\n    margin: 16px auto;\n  }\n}\n", -1)
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(ExampleTemp, {
          id: '<tbody>',
          list: list,
          pdfNextPageShow: true,
          isnoexample: true
        })
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(ExampleTemp, {
          id: '<td>',
          list: list,
          pdfNextPageShow: true,
          isnoexample: true
        })
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createVNode(ExampleTemp, {
          id: '<th>',
          list: list,
          pdfNextPageShow: true,
          isnoexample: true
        })
      ]),
      _createElementVNode("div", _hoisted_12, [
        _createVNode(ExampleTemp, {
          id: '<thead>',
          list: list,
          pdfNextPageShow: true,
          isnoexample: true
        })
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createVNode(ExampleTemp, {
          id: '<tr>',
          list: list,
          pdfNextPageShow: true,
          isnoexample: true
        })
      ]),
      _createVNode(ExampleTemp, {
        id: '<form>',
        list: list,
        pdfNextPageShow: true
      }, {
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[71] || (_cache[71] = _createTextVNode("")),
            _createElementVNode("div", _hoisted_14, [
              _cache[66] || (_cache[66] = _createTextVNode("\n  ")),
              _cache[67] || (_cache[67] = _createElementVNode("h3", null, [
                _createTextVNode("一般的な(vue.jsを使わない)"),
                _createElementVNode("br"),
                _createTextVNode("お問い合わせフォーム")
              ], -1)),
              _cache[68] || (_cache[68] = _createTextVNode("\n\n")),
              _createVNode(Marker, null, {
                default: _withCtx(() => _cache[64] || (_cache[64] = [
                  _createTextVNode("  "),
                  _createElementVNode("form", {
                    class: "highlight",
                    action: "https://example.com/submit",
                    method: "POST"
                  }, [
                    _createTextVNode("\n    "),
                    _createElementVNode("label", { for: "name" }, "名前"),
                    _createElementVNode("br"),
                    _createTextVNode("\n    "),
                    _createElementVNode("input", {
                      type: "text",
                      id: "name",
                      name: "name",
                      placeholder: "名前を入力してください",
                      required: ""
                    }),
                    _createElementVNode("br"),
                    _createElementVNode("br"),
                    _createTextVNode("\n\n    "),
                    _createElementVNode("label", { for: "email" }, "メールアドレス"),
                    _createElementVNode("br"),
                    _createTextVNode("\n    "),
                    _createElementVNode("input", {
                      type: "email",
                      id: "email",
                      name: "email",
                      placeholder: "メールアドレスを入力してください",
                      required: ""
                    }),
                    _createElementVNode("br"),
                    _createElementVNode("br"),
                    _createTextVNode("\n\n    "),
                    _createElementVNode("label", { for: "message" }, "メッセージ"),
                    _createElementVNode("br"),
                    _createTextVNode("\n    "),
                    _createElementVNode("textarea", {
                      id: "message",
                      name: "message",
                      rows: "4",
                      placeholder: "メッセージを入力してください"
                    }),
                    _createElementVNode("br"),
                    _createElementVNode("br"),
                    _createTextVNode("\n\n    "),
                    _createElementVNode("button", {
                      class: "btn btn-white",
                      type: "submit"
                    }, "送信"),
                    _createTextVNode("\n    "),
                    _createElementVNode("button", {
                      class: "btn btn-white",
                      type: "reset"
                    }, "リセット"),
                    _createTextVNode("\n  ")
                  ], -1)
                ])),
                _: 1
              }),
              _cache[69] || (_cache[69] = _createTextVNode("\n")),
              _createVNode(_component_my_show_only, null, {
                default: _withCtx(() => _cache[65] || (_cache[65] = [
                  _createElementVNode("div", null, "\n    ※ actionに指定したページ(https://example.com/submit)はダミーページです。ページは存在しないため送信したデータを受け取れません。そのため送信ボタンを押した場合、ページは遷移されますがエラーメッセージが表示されます。ブラウザの「戻る」ボタンから元のページに戻れます。\n  ", -1)
                ])),
                _: 1
              }),
              _cache[70] || (_cache[70] = _createTextVNode("\n"))
            ]),
            _cache[72] || (_cache[72] = _createTextVNode("\n"))
          ])
        ]),
        script: _withCtx(() => _cache[73] || (_cache[73] = [
          _createElementVNode("pre", null, "\n", -1)
        ])),
        style: _withCtx(() => _cache[74] || (_cache[74] = [
          _createElementVNode("pre", null, ".form-sample {\n  form {\n    margin-bottom: 16px;\n    input, textarea {\n      padding: 16px;\n      border-radius: 16px;\n      width: 100%;\n    }\n    textarea {\n      background: white;\n    }\n    @media (min-width: 520px) {\n      input, textarea {\n        width: 340px;\n      }\n    }\n  }\n}\n", -1)
        ])),
        _: 1
      }),
      _createVNode(ExampleTemp, {
        id: '<button>',
        list: list
      }, {
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[92] || (_cache[92] = _createTextVNode("")),
            _createElementVNode("div", _hoisted_15, [
              _createVNode(_component_my_code_only, null, {
                default: _withCtx(() => [
                  _cache[80] || (_cache[80] = _createTextVNode("\n  ")),
                  _cache[81] || (_cache[81] = _createElementVNode("div", null, "\n    カウンター: {{buttonCnt}}\n  ", -1)),
                  _cache[82] || (_cache[82] = _createTextVNode("\n  ")),
                  _createElementVNode("div", _hoisted_16, [
                    _cache[77] || (_cache[77] = _createTextVNode("\n    ")),
                    _createVNode(Marker, null, {
                      default: _withCtx(() => _cache[75] || (_cache[75] = [
                        _createTextVNode("<button class=\"btn btn-white\" @click=\"buttonMinusFn()\" :disabled=\"isDisabledButton()\"≷減らすボタン</button>")
                      ])),
                      _: 1
                    }),
                    _cache[78] || (_cache[78] = _createTextVNode("\n    ")),
                    _createVNode(Marker, null, {
                      default: _withCtx(() => _cache[76] || (_cache[76] = [
                        _createTextVNode("<button class=\"btn btn-white\" @click=\"buttonAddFn()\">増やすボタン</button>")
                      ])),
                      _: 1
                    }),
                    _cache[79] || (_cache[79] = _createTextVNode("\n  "))
                  ]),
                  _cache[83] || (_cache[83] = _createTextVNode("\n"))
                ]),
                _: 1
              }),
              _cache[90] || (_cache[90] = _createTextVNode("\n")),
              _createVNode(_component_my_show_only, null, {
                default: _withCtx(() => [
                  _cache[87] || (_cache[87] = _createTextVNode("\n  ")),
                  _createElementVNode("div", null, "\n    カウンター: " + _toDisplayString(buttonCnt.value) + "\n  ", 1),
                  _cache[88] || (_cache[88] = _createTextVNode("\n  ")),
                  _createElementVNode("div", _hoisted_17, [
                    _cache[84] || (_cache[84] = _createTextVNode("\n    ")),
                    _createElementVNode("button", {
                      class: "btn btn-white highlight",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (buttonMinusFn())),
                      disabled: isDisabledButton()
                    }, "減らすボタン", 8, _hoisted_18),
                    _cache[85] || (_cache[85] = _createTextVNode("\n    ")),
                    _createElementVNode("button", {
                      class: "btn btn-white highlight",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (buttonAddFn()))
                    }, "増やすボタン"),
                    _cache[86] || (_cache[86] = _createTextVNode("\n  "))
                  ]),
                  _cache[89] || (_cache[89] = _createTextVNode("\n"))
                ]),
                _: 1
              }),
              _cache[91] || (_cache[91] = _createTextVNode("\n"))
            ]),
            _cache[93] || (_cache[93] = _createTextVNode("\n"))
          ])
        ]),
        script: _withCtx(() => _cache[94] || (_cache[94] = [
          _createElementVNode("pre", null, "import { ref } from \"vue\";\nconst buttonCnt = ref(0);\nfunction buttonAddFn() {\n  buttonCnt.value++;\n}\nfunction buttonMinusFn() {\n  if (buttonCnt.value <= 0) {\n    return;\n  }\n  buttonCnt.value--;\n}\n", -1)
        ])),
        style: _withCtx(() => _cache[95] || (_cache[95] = [
          _createElementVNode("pre", null, ".button-sample {\n  button:first-child {\n  }\n  .btn-wrap {\n    margin-top: 8px;\n    @media (max-width: 519px) {\n      .btn {\n        width: 100%;\n        margin-bottom: 8px;\n      }\n    }\n    @media (min-width: 520px) {\n      .btn {\n        width: initial;\n      }\n    }\n  }\n}\n", -1)
        ])),
        _: 1
      }),
      _createVNode(ExampleTemp, {
        id: '<datalist>',
        list: list,
        pdfNextPageShow: true,
        isnobrowsershowcheckbox: true
      }, {
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[107] || (_cache[107] = _createTextVNode("")),
            _createElementVNode("div", null, [
              _cache[103] || (_cache[103] = _createTextVNode("\n  ")),
              _cache[104] || (_cache[104] = _createElementVNode("h3", null, "好きな色を選んでください", -1)),
              _cache[105] || (_cache[105] = _createTextVNode("\n  ")),
              _createElementVNode("form", null, [
                _cache[97] || (_cache[97] = _createTextVNode("\n      ")),
                _cache[98] || (_cache[98] = _createElementVNode("label", { for: "color" }, "色：", -1)),
                _cache[99] || (_cache[99] = _createTextVNode("\n      ")),
                _cache[100] || (_cache[100] = _createElementVNode("input", {
                  list: "colors",
                  id: "color",
                  name: "color",
                  placeholder: "色を選んでください"
                }, null, -1)),
                _cache[101] || (_cache[101] = _createTextVNode("\n")),
                _createVNode(Marker, null, {
                  default: _withCtx(() => _cache[96] || (_cache[96] = [
                    _createTextVNode("      "),
                    _createElementVNode("datalist", { id: "colors" }, [
                      _createTextVNode("\n        "),
                      _createElementVNode("option", { value: "赤" }),
                      _createTextVNode("\n        "),
                      _createElementVNode("option", { value: "青" }),
                      _createTextVNode("\n        "),
                      _createElementVNode("option", { value: "緑" }),
                      _createTextVNode("\n        "),
                      _createElementVNode("option", { value: "黄色" }),
                      _createTextVNode("\n        "),
                      _createElementVNode("option", { value: "ピンク" }),
                      _createTextVNode("\n        "),
                      _createElementVNode("option", { value: "オレンジ" }),
                      _createTextVNode("\n        "),
                      _createElementVNode("option", { value: "黒" }),
                      _createTextVNode("\n        "),
                      _createElementVNode("option", { value: "白" }),
                      _createTextVNode("\n      ")
                    ], -1)
                  ])),
                  _: 1
                }),
                _cache[102] || (_cache[102] = _createTextVNode("\n  "))
              ]),
              _cache[106] || (_cache[106] = _createTextVNode("\n"))
            ]),
            _cache[108] || (_cache[108] = _createTextVNode("\n"))
          ])
        ]),
        script: _withCtx(() => _cache[109] || (_cache[109] = [
          _createElementVNode("pre", null, "", -1)
        ])),
        style: _withCtx(() => _cache[110] || (_cache[110] = [
          _createElementVNode("pre", null, "", -1)
        ])),
        _: 1
      }),
      _createVNode(ExampleTemp, {
        id: '<input>',
        list: list,
        pdfNextPageShow: true
      }, {
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[120] || (_cache[120] = _createTextVNode("")),
            _createElementVNode("div", _hoisted_19, [
              _cache[116] || (_cache[116] = _createTextVNode("\n  ")),
              _createVNode(_component_my_code_only, null, {
                default: _withCtx(() => _cache[111] || (_cache[111] = [
                  _createElementVNode("p", null, "\n    こんにちは、{{inputMyName ? inputMyName : \"匿名\"}}さん！\n  ", -1)
                ])),
                _: 1
              }),
              _cache[117] || (_cache[117] = _createTextVNode("\n\n")),
              _createVNode(_component_my_show_only, null, {
                default: _withCtx(() => [
                  _createElementVNode("p", null, "\n    こんにちは、" + _toDisplayString(inputMyName.value ? inputMyName.value : "匿名") + "さん！\n  ", 1)
                ]),
                _: 1
              }),
              _cache[118] || (_cache[118] = _createTextVNode("\n  ")),
              _createElementVNode("form", null, [
                _cache[112] || (_cache[112] = _createTextVNode("\n    ")),
                _cache[113] || (_cache[113] = _createElementVNode("label", { for: "myname" }, "氏名: ", -1)),
                _cache[114] || (_cache[114] = _createTextVNode("\n    ")),
                _createVNode(Marker, null, {
                  default: _withCtx(() => [
                    _withDirectives(_createElementVNode("input", {
                      type: "text",
                      id: "myname",
                      class: "highlight",
                      name: "myname",
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((inputMyName).value = $event))
                    }, null, 512), [
                      [_vModelText, inputMyName.value]
                    ])
                  ]),
                  _: 1
                }),
                _cache[115] || (_cache[115] = _createTextVNode("\n  "))
              ]),
              _cache[119] || (_cache[119] = _createTextVNode("\n"))
            ]),
            _cache[121] || (_cache[121] = _createTextVNode("\n"))
          ])
        ]),
        script: _withCtx(() => _cache[122] || (_cache[122] = [
          _createElementVNode("pre", null, "import { ref } from \"vue\";\nconst inputMyName = ref(\"\");\n", -1)
        ])),
        style: _withCtx(() => _cache[123] || (_cache[123] = [
          _createElementVNode("pre", null, ".input-sample {\n  p {\n    text-align: center;\n    margin-bottom: 12px;\n  }\n}\n", -1)
        ])),
        _: 1
      }),
      _createVNode(ExampleTemp, {
        id: '<label>',
        list: list,
        pdfNextPageShow: true
      }, {
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[155] || (_cache[155] = _createTextVNode("")),
            _createElementVNode("div", _hoisted_20, [
              _cache[152] || (_cache[152] = _createTextVNode("\n  ")),
              _createElementVNode("div", null, [
                _cache[137] || (_cache[137] = _createTextVNode("\n    ")),
                _cache[138] || (_cache[138] = _createElementVNode("h3", null, "方法１", -1)),
                _cache[139] || (_cache[139] = _createTextVNode("\n    ")),
                _createElementVNode("div", null, [
                  _cache[127] || (_cache[127] = _createTextVNode("\n      ")),
                  _cache[128] || (_cache[128] = _createElementVNode("input", {
                    type: "radio",
                    name: "question0",
                    id: "question0"
                  }, null, -1)),
                  _cache[129] || (_cache[129] = _createTextVNode()),
                  _createVNode(Marker, null, {
                    default: _withCtx(() => _cache[124] || (_cache[124] = [
                      _createElementVNode("label", {
                        class: "highlight",
                        for: "question0"
                      }, "無回答", -1)
                    ])),
                    _: 1
                  }),
                  _cache[130] || (_cache[130] = _createTextVNode("\n      ")),
                  _cache[131] || (_cache[131] = _createElementVNode("input", {
                    type: "radio",
                    name: "question0",
                    id: "question1"
                  }, null, -1)),
                  _cache[132] || (_cache[132] = _createTextVNode()),
                  _createVNode(Marker, null, {
                    default: _withCtx(() => _cache[125] || (_cache[125] = [
                      _createElementVNode("label", {
                        class: "highlight",
                        for: "question1"
                      }, "はい", -1)
                    ])),
                    _: 1
                  }),
                  _cache[133] || (_cache[133] = _createTextVNode("\n      ")),
                  _cache[134] || (_cache[134] = _createElementVNode("input", {
                    type: "radio",
                    name: "question0",
                    id: "question2"
                  }, null, -1)),
                  _cache[135] || (_cache[135] = _createTextVNode()),
                  _createVNode(Marker, null, {
                    default: _withCtx(() => _cache[126] || (_cache[126] = [
                      _createElementVNode("label", {
                        class: "highlight",
                        for: "question2"
                      }, "いいえ", -1)
                    ])),
                    _: 1
                  }),
                  _cache[136] || (_cache[136] = _createTextVNode("\n    "))
                ]),
                _cache[140] || (_cache[140] = _createTextVNode("\n  "))
              ]),
              _cache[153] || (_cache[153] = _createTextVNode("\n  ")),
              _createElementVNode("div", null, [
                _cache[148] || (_cache[148] = _createTextVNode("\n    ")),
                _cache[149] || (_cache[149] = _createElementVNode("h3", null, "方法２", -1)),
                _cache[150] || (_cache[150] = _createTextVNode("\n    ")),
                _createElementVNode("div", null, [
                  _cache[144] || (_cache[144] = _createTextVNode("\n      ")),
                  _createVNode(Marker, null, {
                    default: _withCtx(() => _cache[141] || (_cache[141] = [
                      _createElementVNode("label", { class: "highlight" }, [
                        _createElementVNode("input", {
                          type: "radio",
                          name: "question1"
                        }),
                        _createTextVNode("無回答")
                      ], -1)
                    ])),
                    _: 1
                  }),
                  _cache[145] || (_cache[145] = _createTextVNode("\n      ")),
                  _createVNode(Marker, null, {
                    default: _withCtx(() => _cache[142] || (_cache[142] = [
                      _createElementVNode("label", { class: "highlight" }, [
                        _createElementVNode("input", {
                          type: "radio",
                          name: "question1"
                        }),
                        _createTextVNode("はい")
                      ], -1)
                    ])),
                    _: 1
                  }),
                  _cache[146] || (_cache[146] = _createTextVNode("\n      ")),
                  _createVNode(Marker, null, {
                    default: _withCtx(() => _cache[143] || (_cache[143] = [
                      _createElementVNode("label", { class: "highlight" }, [
                        _createElementVNode("input", {
                          type: "radio",
                          name: "question1"
                        }),
                        _createTextVNode("いいえ")
                      ], -1)
                    ])),
                    _: 1
                  }),
                  _cache[147] || (_cache[147] = _createTextVNode("\n    "))
                ]),
                _cache[151] || (_cache[151] = _createTextVNode("\n  "))
              ]),
              _cache[154] || (_cache[154] = _createTextVNode("\n"))
            ]),
            _cache[156] || (_cache[156] = _createTextVNode("\n"))
          ])
        ]),
        style: _withCtx(() => _cache[157] || (_cache[157] = [
          _createElementVNode("pre", null, ".label-sample {\n  label {\n    margin-right: 8px;\n  }\n}\n", -1)
        ])),
        _: 1
      }),
      _createVNode(ExampleTemp, {
        id: '<option>',
        list: list,
        pdfNextPageShow: true,
        isnobrowsershowcheckbox: true
      }, {
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[175] || (_cache[175] = _createTextVNode("")),
            _createElementVNode("div", _hoisted_21, [
              _cache[173] || (_cache[173] = _createTextVNode("\n  ")),
              _createElementVNode("form", null, [
                _cache[167] || (_cache[167] = _createTextVNode("\n    ")),
                _cache[168] || (_cache[168] = _createElementVNode("label", { for: "option-color" }, "好きな色を選んでください：", -1)),
                _cache[169] || (_cache[169] = _createTextVNode("\n    ")),
                _cache[170] || (_cache[170] = _createElementVNode("select", {
                  id: "option-color",
                  name: "option-color"
                }, [
                  _createTextVNode("\n      "),
                  _createElementVNode("option", { value: "red" }, "赤"),
                  _createTextVNode("\n      "),
                  _createElementVNode("option", { value: "green" }, "緑"),
                  _createTextVNode("\n      "),
                  _createElementVNode("option", { value: "blue" }, "青"),
                  _createTextVNode("\n      "),
                  _createElementVNode("option", { value: "yellow" }, "黄色"),
                  _createTextVNode("\n    ")
                ], -1)),
                _cache[171] || (_cache[171] = _createTextVNode("\n    ")),
                _createElementVNode("select", _hoisted_22, [
                  _cache[162] || (_cache[162] = _createTextVNode("\n      ")),
                  _createVNode(Marker, null, {
                    default: _withCtx(() => _cache[158] || (_cache[158] = [
                      _createElementVNode("option", { value: "red" }, "赤", -1)
                    ])),
                    _: 1
                  }),
                  _cache[163] || (_cache[163] = _createTextVNode("\n      ")),
                  _createVNode(Marker, null, {
                    default: _withCtx(() => _cache[159] || (_cache[159] = [
                      _createElementVNode("option", { value: "green" }, "緑", -1)
                    ])),
                    _: 1
                  }),
                  _cache[164] || (_cache[164] = _createTextVNode("\n      ")),
                  _createVNode(Marker, null, {
                    default: _withCtx(() => _cache[160] || (_cache[160] = [
                      _createElementVNode("option", { value: "blue" }, "青", -1)
                    ])),
                    _: 1
                  }),
                  _cache[165] || (_cache[165] = _createTextVNode("\n      ")),
                  _createVNode(Marker, null, {
                    default: _withCtx(() => _cache[161] || (_cache[161] = [
                      _createElementVNode("option", { value: "yellow" }, "黄色", -1)
                    ])),
                    _: 1
                  }),
                  _cache[166] || (_cache[166] = _createTextVNode("\n    "))
                ]),
                _cache[172] || (_cache[172] = _createTextVNode("\n  "))
              ]),
              _cache[174] || (_cache[174] = _createTextVNode("\n"))
            ]),
            _cache[176] || (_cache[176] = _createTextVNode("\n"))
          ])
        ]),
        script: _withCtx(() => _cache[177] || (_cache[177] = [
          _createElementVNode("pre", null, "", -1)
        ])),
        style: _withCtx(() => _cache[178] || (_cache[178] = [
          _createElementVNode("pre", null, ".option-sample {\n  select {\n    background: white;\n  }\n}\n", -1)
        ])),
        _: 1
      }),
      _createVNode(ExampleTemp, {
        id: '<br>',
        list: list,
        pdfNextPageShow: true,
        isnobrowsershowcheckbox: true
      }, {
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[184] || (_cache[184] = _createTextVNode("")),
            _createElementVNode("p", null, [
              _cache[181] || (_cache[181] = _createTextVNode("\n  これは最初の行です。")),
              _createVNode(Marker, null, {
                default: _withCtx(() => _cache[179] || (_cache[179] = [
                  _createElementVNode("br", null, null, -1)
                ])),
                _: 1
              }),
              _cache[182] || (_cache[182] = _createTextVNode("\n  これは次の行です。")),
              _createVNode(Marker, null, {
                default: _withCtx(() => _cache[180] || (_cache[180] = [
                  _createElementVNode("br", null, null, -1)
                ])),
                _: 1
              }),
              _cache[183] || (_cache[183] = _createTextVNode("\n  さらに次の行です。\n"))
            ]),
            _cache[185] || (_cache[185] = _createTextVNode("\n"))
          ])
        ]),
        _: 1
      }),
      _createVNode(ExampleTemp, {
        id: '<a>',
        list: list,
        pdfNextPageShow: true
      }, {
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[195] || (_cache[195] = _createTextVNode("")),
            _createElementVNode("div", _hoisted_23, [
              _cache[192] || (_cache[192] = _createTextVNode("\n  ")),
              _createElementVNode("div", null, [
                _cache[187] || (_cache[187] = _createTextVNode("\n    こちらは")),
                _createVNode(Marker, null, {
                  default: _withCtx(() => _cache[186] || (_cache[186] = [
                    _createElementVNode("a", {
                      class: "highlight",
                      href: "https://www.snssoft.co.jp"
                    }, "弊社公式サイト", -1)
                  ])),
                  _: 1
                }),
                _cache[188] || (_cache[188] = _createTextVNode("に飛ぶリンクです。\n  "))
              ]),
              _cache[193] || (_cache[193] = _createTextVNode("\n  ")),
              _createElementVNode("div", null, [
                _cache[190] || (_cache[190] = _createTextVNode("\n    ここをクリックすると")),
                _createVNode(Marker, null, {
                  default: _withCtx(() => _cache[189] || (_cache[189] = [
                    _createElementVNode("a", {
                      class: "highlight",
                      href: "#layout"
                    }, "ページのトップ", -1)
                  ])),
                  _: 1
                }),
                _cache[191] || (_cache[191] = _createTextVNode("に飛びます。\n  "))
              ]),
              _cache[194] || (_cache[194] = _createTextVNode("\n"))
            ]),
            _cache[196] || (_cache[196] = _createTextVNode("\n"))
          ])
        ]),
        _: 1
      }),
      _createVNode(ExampleTemp, {
        id: '<i>',
        list: list,
        pdfNextPageShow: true
      }, {
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[200] || (_cache[200] = _createTextVNode("")),
            _createElementVNode("p", null, [
              _cache[198] || (_cache[198] = _createTextVNode("\n  「新世紀エヴァンゲリオン」の由来は、ラテン語の「")),
              _createVNode(Marker, null, {
                default: _withCtx(() => _cache[197] || (_cache[197] = [
                  _createElementVNode("i", { class: "highlight" }, "euangelion", -1)
                ])),
                _: 1
              }),
              _cache[199] || (_cache[199] = _createTextVNode("」と言われています。\n"))
            ]),
            _cache[201] || (_cache[201] = _createTextVNode("\n"))
          ])
        ]),
        _: 1
      }),
      _createVNode(ExampleTemp, {
        id: '<span>',
        list: list,
        pdfNextPageShow: true
      }, {
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[205] || (_cache[205] = _createTextVNode("")),
            _createElementVNode("div", _hoisted_24, [
              _cache[203] || (_cache[203] = _createTextVNode("\n  私は")),
              _createVNode(Marker, null, {
                default: _withCtx(() => _cache[202] || (_cache[202] = [
                  _createElementVNode("span", { class: "highlight apple" }, "リンゴ", -1)
                ])),
                _: 1
              }),
              _cache[204] || (_cache[204] = _createTextVNode("が好きです。\n"))
            ]),
            _cache[206] || (_cache[206] = _createTextVNode("\n"))
          ])
        ]),
        style: _withCtx(() => _cache[207] || (_cache[207] = [
          _createElementVNode("pre", null, ".span-sample {\n  .apple {\n    color: red;\n  }\n}\n", -1)
        ])),
        _: 1
      })
    ])
  ]))
}
}

})