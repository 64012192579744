<template>
  <div class="gaiyou">
    <slot name="title"></slot>
  </div>
  <p class="description">
    <slot name="description"></slot>
  </p>
</template>

<style scoped>
.gaiyou {
  background: #cccccc;
  padding: 9px;
  text-align: left;
}
.description {
  font-size: 15px;
  text-align: left;
  padding: 16px;
}
</style>