<template>
<div class="main01 reference html">

    <h1 class="midasi text-xl">HTMLリファレンス</h1>
    <Reference :list="list" />
    <div class="examples">
      <!-- --------------------
       <div>タグ
      -------------------- -->
      <ExampleTemp :id="'<div>'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div lang="en-US">
  <h3>The Door to Tomorrow</h3>
  <p>
    The morning breeze gently blows, A new day begins today.  The tears and troubles of yesterday are now in the past, A new opportunity is waiting for you.
  </p>
<Marker>  <div lang="en-GB" class="highlight">
    <p>
      No matter how small the step, The courage to take it will change your future.  Even if the road is steep, You can overcome it. Believe in yourself and move forward.
    </p>
  </div></Marker>
  <p>
    No matter the time, you are not alone.  Someone is by your side, cheering you on.  Open your heart, hold onto hope, And together, let’s open the door to tomorrow.
  </p>
</div>
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
       <hr>タグ
      -------------------- -->
      <ExampleTemp :id="'<hr>'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="hr-sample">
  <h3>テーマA</h3>
  <div>このテーマAの内容です。</div>
  <div class="py-4 highlight">
    <Marker><hr /></Marker>
  </div>
  <h3>テーマB</h3>
  <div>次のテーマBの内容です。</div>
</div>
</pre>
        </template>
        <template #style>
<pre>
.hr-sample {
  .py-4 {
    padding-top: 1rem; /* 16px */
    padding-bottom: 1rem; /* 16px */
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
       <p>タグ
      -------------------- -->
      <ExampleTemp :id="'<p>'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="p-sample">
  <h3>段落の例</h3><Marker>
  <p class="highlight">
    これは最初の段落です。段落は文章を分けるために使います。
  </p></Marker>
<Marker>  <p class="highlight">
    これは二番目の段落です。段落ごとにスペースができて、読みやすくなります。
  </p></Marker>
<Marker>  <p class="highlight">
    これは三番目の段落です。こうして段落を分けると、文章がすっきりして見やすくなります。
  </p></Marker>
</div>
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
       <h1>・<h2>・<h3>・<h4>・<h5>・<h6>タグ
      -------------------- -->
      <ExampleTemp :id="'<h1>・<h2>・<h3>・<h4>・<h5>・<h6>'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="hx-sample">
  <Marker><h1><span class="highlight">見出し1</span></h1></Marker>
  <Marker><h2><span class="highlight">見出し2</span></h2></Marker>
  <Marker><h3><span class="highlight">見出し3</span></h3></Marker>
  <Marker><h4><span class="highlight">見出し4</span></h4></Marker>
  <Marker><h5><span class="highlight">見出し5</span></h5></Marker>
  <Marker><h6><span class="highlight">見出し6</span></h6></Marker>
</div>
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
       <table>タグ
      -------------------- -->
      <ExampleTemp :id="'<table>'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="table-sample">
  <h3>成績表</h3>
<Marker>  <table class="highlight" border="1">
    <thead>
      <tr>
        <th>名前</th>
        <th>数学</th>
        <th>英語</th>
        <th>理科</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>佐藤太郎</td>
        <td>80</td>
        <td>75</td>
        <td>90</td>
      </tr>
      <tr>
        <td>鈴木花子</td>
        <td>85</td>
        <td>80</td>
        <td>95</td>
      </tr>
      <tr>
        <td>山田一郎</td>
        <td>70</td>
        <td>60</td>
        <td>80</td>
      </tr>
    </tbody>
  </table></Marker>
</div>
</pre>
        </template>
        <template #script>
<pre>
</pre>
        </template>
        <template #style>
<pre>
.table-sample {
  table {
    margin: 16px auto;
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
       <tbody>タグ
      -------------------- -->
      <div id="<tbody>" class="example-wrap pdf_next_page_no">
        <ExampleTemp :id="'<tbody>'" :list="list" :pdfNextPageShow="true" :isnoexample="true">
        </ExampleTemp>
      </div>
      <!-- --------------------
       <td>タグ
      -------------------- -->
      <div id="<td>" class="example-wrap pdf_next_page_no">
        <ExampleTemp :id="'<td>'" :list="list" :pdfNextPageShow="true" :isnoexample="true">
        </ExampleTemp>
      </div>
      <!-- --------------------
       <th>タグ
      -------------------- -->
      <div id="<th>" class="example-wrap pdf_next_page_no">
        <ExampleTemp :id="'<th>'" :list="list" :pdfNextPageShow="true" :isnoexample="true">
        </ExampleTemp>
      </div>
      <!-- --------------------
       <thead>タグ
      -------------------- -->
      <div id="<thead>" class="example-wrap pdf_next_page_no">
        <ExampleTemp :id="'<thead>'" :list="list" :pdfNextPageShow="true" :isnoexample="true">
        </ExampleTemp>
      </div>
      <!-- --------------------
       <tr>タグ
      -------------------- -->
      <div id="<tr>" class="example-wrap pdf_next_page_no">
        <ExampleTemp :id="'<tr>'" :list="list" :pdfNextPageShow="true" :isnoexample="true">
        </ExampleTemp>
      </div>
      <!-- --------------------
       <form>タグ
      -------------------- -->
      <ExampleTemp :id="'<form>'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="form-sample">
  <h3>一般的な(vue.jsを使わない)<br />お問い合わせフォーム</h3>

<Marker>  <form class="highlight" action="https://example.com/submit" method="POST">
    <label for="name">名前</label><br />
    <input type="text" id="name" name="name" placeholder="名前を入力してください" required><br><br>

    <label for="email">メールアドレス</label><br />
    <input type="email" id="email" name="email" placeholder="メールアドレスを入力してください" required><br><br>

    <label for="message">メッセージ</label><br />
    <textarea id="message" name="message" rows="4" placeholder="メッセージを入力してください"></textarea><br><br>

    <button class="btn btn-white" type="submit">送信</button>
    <button class="btn btn-white" type="reset">リセット</button>
  </form></Marker>
<my-show-only><div>
    ※ actionに指定したページ(https://example.com/submit)はダミーページです。ページは存在しないため送信したデータを受け取れません。そのため送信ボタンを押した場合、ページは遷移されますがエラーメッセージが表示されます。ブラウザの「戻る」ボタンから元のページに戻れます。
  </div></my-show-only>
</div>
</pre>
        </template>
        <template #script>
<pre>

</pre>
        </template>
        <template #style>
<pre>
.form-sample {
  form {
    margin-bottom: 16px;
    input, textarea {
      padding: 16px;
      border-radius: 16px;
      width: 100%;
    }
    textarea {
      background: white;
    }
    @media (min-width: 520px) {
      input, textarea {
        width: 340px;
      }
    }
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
       <button>タグ
      -------------------- -->
      <ExampleTemp :id="'<button>'" :list="list">
        <template #template>
<pre>
<div class="button-sample"><my-code-only>
  <div v-pre>
    カウンター: {{buttonCnt}}
  </div>
  <div class="btn-wrap">
    <Marker>&lt;button class="btn btn-white" @click="buttonMinusFn()" :disabled="isDisabledButton()"&gl;減らすボタン&lt;/button&gt;</Marker>
    <Marker>&lt;button class="btn btn-white" @click="buttonAddFn()"&gt;増やすボタン&lt;/button&gt;</Marker>
  </div>
</my-code-only>
<my-show-only>
  <div>
    カウンター: {{buttonCnt}}
  </div>
  <div class="btn-wrap">
    <button class="btn btn-white highlight" @click="buttonMinusFn()" :disabled="isDisabledButton()">減らすボタン</button>
    <button class="btn btn-white highlight" @click="buttonAddFn()">増やすボタン</button>
  </div>
</my-show-only>
</div>
</pre>
        </template>
        <template #script>
<pre>
import { ref } from "vue";
const buttonCnt = ref(0);
function buttonAddFn() {
  buttonCnt.value++;
}
function buttonMinusFn() {
  if (buttonCnt.value &lt;= 0) {
    return;
  }
  buttonCnt.value--;
}
</pre>
        </template>
        <template #style>
<pre>
.button-sample {
  button:first-child {
  }
  .btn-wrap {
    margin-top: 8px;
    @media (max-width: 519px) {
      .btn {
        width: 100%;
        margin-bottom: 8px;
      }
    }
    @media (min-width: 520px) {
      .btn {
        width: initial;
      }
    }
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
       <datalist>タグ
      -------------------- -->
      <ExampleTemp :id="'<datalist>'" :list="list" :pdfNextPageShow="true" :isnobrowsershowcheckbox="true">
        <template #template>
<pre>
<div>
  <h3>好きな色を選んでください</h3>
  <form>
      <label for="color">色：</label>
      <input list="colors" id="color" name="color" placeholder="色を選んでください">
<Marker>      <datalist id="colors">
        <option value="赤" />
        <option value="青" />
        <option value="緑" />
        <option value="黄色" />
        <option value="ピンク" />
        <option value="オレンジ" />
        <option value="黒" />
        <option value="白" />
      </datalist></Marker>
  </form>
</div>
</pre>
        </template>
        <template #script>
<pre>
</pre>
        </template>
        <template #style>
<pre>
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
       <input>タグ
      -------------------- -->
      <ExampleTemp :id="'<input>'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="input-sample">
  <my-code-only><p v-pre>
    こんにちは、{{inputMyName ? inputMyName : "匿名"}}さん！
  </p></my-code-only>

<my-show-only><p>
    こんにちは、{{inputMyName ? inputMyName : "匿名"}}さん！
  </p></my-show-only>
  <form>
    <label for="myname">氏名: </label>
    <Marker><input type=text id="myname" class="highlight" name="myname" v-model="inputMyName"></Marker>
  </form>
</div>
</pre>
        </template>
        <template #script>
<pre>
import { ref } from "vue";
const inputMyName = ref("");
</pre>
        </template>
        <template #style>
<pre>
.input-sample {
  p {
    text-align: center;
    margin-bottom: 12px;
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
       <label>タグ
      -------------------- -->
      <ExampleTemp :id="'<label>'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="label-sample">
  <div>
    <h3>方法１</h3>
    <div>
      <input type="radio" name="question0" id="question0"> <Marker><label class="highlight" for="question0">無回答</label></Marker>
      <input type="radio" name="question0" id="question1"> <Marker><label class="highlight" for="question1">はい</label></Marker>
      <input type="radio" name="question0" id="question2"> <Marker><label class="highlight" for="question2">いいえ</label></Marker>
    </div>
  </div>
  <div>
    <h3>方法２</h3>
    <div>
      <Marker><label class="highlight"><input type="radio" name="question1">無回答</label></Marker>
      <Marker><label class="highlight"><input type="radio" name="question1">はい</label></Marker>
      <Marker><label class="highlight"><input type="radio" name="question1">いいえ</label></Marker>
    </div>
  </div>
</div>
</pre>
        </template>
        <template #style>
<pre>
.label-sample {
  label {
    margin-right: 8px;
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
       <option>タグ
      -------------------- -->
      <ExampleTemp :id="'<option>'" :list="list" :pdfNextPageShow="true" :isnobrowsershowcheckbox="true">
        <template #template>
<pre>
<div class="option-sample">
  <form>
    <label for="option-color">好きな色を選んでください：</label>
    <select id="option-color" name="option-color">
      <option value="red">赤</option>
      <option value="green">緑</option>
      <option value="blue">青</option>
      <option value="yellow">黄色</option>
    </select>
    <select id="option-color" name="option-color">
      <Marker><option value="red">赤</option></Marker>
      <Marker><option value="green">緑</option></Marker>
      <Marker><option value="blue">青</option></Marker>
      <Marker><option value="yellow">黄色</option></Marker>
    </select>
  </form>
</div>
</pre>
        </template>
        <template #script>
<pre>
</pre>
        </template>
        <template #style>
<pre>
.option-sample {
  select {
    background: white;
  }
}
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
       <br>タグ
      -------------------- -->
      <ExampleTemp :id="'<br>'" :list="list" :pdfNextPageShow="true" :isnobrowsershowcheckbox="true">
        <template #template>
<pre>
<p>
  これは最初の行です。<Marker><br></Marker>
  これは次の行です。<Marker><br></Marker>
  さらに次の行です。
</p>
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
       <a>タグ
      -------------------- -->
      <ExampleTemp :id="'<a>'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="a-sample">
  <div>
    こちらは<Marker><a class="highlight" href="https://www.snssoft.co.jp">弊社公式サイト</a></Marker>に飛ぶリンクです。
  </div>
  <div>
    ここをクリックすると<Marker><a class="highlight" href="#layout">ページのトップ</a></Marker>に飛びます。
  </div>
</div>
</pre>
        </template>
      </ExampleTemp>


      <!-- --------------------
       <i>タグ
      -------------------- -->
      <ExampleTemp :id="'<i>'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<p>
  「新世紀エヴァンゲリオン」の由来は、ラテン語の「<Marker><i class="highlight">euangelion</i></Marker>」と言われています。
</p>
</pre>
        </template>
      </ExampleTemp>
      <!-- --------------------
       <span>タグ
      -------------------- -->
      <ExampleTemp :id="'<span>'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
<div class="span-sample">
  私は<Marker><span class="highlight apple">リンゴ</span></Marker>が好きです。
</div>
</pre>
        </template>
        <template #style>
<pre>
.span-sample {
  .apple {
    color: red;
  }
}
</pre>
        </template>
      </ExampleTemp>


      <!-- --------------------
       <>タグ
      -------------------- -->
      <!--
      <ExampleTemp :id="'<>'" :list="list" :pdfNextPageShow="true">
        <template #template>
<pre>
</pre>
        </template>
        <template #script>
<pre>
</pre>
        </template>
        <template #style>
<pre>
</pre>
        </template>
      </ExampleTemp>
      -->

    </div>


</div>
</template>

<script setup lang="tsx">
import { ref } from "vue";
import { useStore } from "vuex";
import Reference from "@/components/example/ReferenceComponents.vue";
import ExampleTemp from "@/components/example/ExampleTempComponents.vue";
import Marker from "@/components/MarkerComponent.vue";
import type { ListType } from "@/types/reference.type";

const store = useStore();
store.dispatch("highlightFalse");

// --------------------
//  input
// --------------------
const inputMyName = ref("");

const list: ListType[] = [
  {
    title: "グルーピング要素",
    list: [
      {
        id: "<div>",
        title: "（特別な意味がない箱）ボックス要素",
        description: "div要素は、特別な意味を付加せず子要素をまとめるコンテナ要素としてスタイリング目的に使用したり、 セクション内の複数の段落をラップしてまとめて意味付けする場合などに役立ちます。",
      }, {
        id: "<hr>",
        title: "水平線",
        description: "文書内でテーマの変化を示す水平線を挿入するために使用されます。別トピックに移行する箇所や物語の場面転換など、セクションの区切りや内容の分割を視覚的に表現するのに役立ちます。セマンティックな意味を持ち、内容のテーマの変化を示すために使用されることが推奨されています。",
      }, {
        id: "<p>",
        title: "段落（パラグラフ）",
        description: "HTMLのpタグは、「段落」を作るためのタグです。段落というのは、文章を区切って読みやすくするためのものです。例えば、手紙や本を読むときに、改行して段落を分けるように、ウェブページでも文章を読みやすくするために段落を分けます。pタグを使うと、文章をきれいに分けて表示することができ、段落ごとに自動で少しのスペース（空き）ができます。",
      },
    ],
  }, {
    title: "セクション",
    list: [
      {
        id: "<h1>・<h2>・<h3>・<h4>・<h5>・<h6>",
        title: "見出し",
        description: "数値が小さいほど大きい文字・大切な意味の見出しになります。",
      }
    ],
  }, {
    title: "テーブル",
    list: [
      {
        id: "<table>",
        title: "テーブル（表）を作成",
        description: "HTMLのtableタグは、「表（テーブル）」を作るためのタグです。表は、データや情報を整理して見やすく並べるために使います。例えば、成績表や、予定表、商品のリストなど、行と列を使ってデータを整理する時に使います。tableタグを使うと、情報を行（横）と列（縦）に分けて表示することができます。",
      }, {
        id: "<tbody>",
        title: "(テーブルの中の) データが入る場所を区切る",
        description: `HTMLのtbodyタグは、HTMLの表（テーブル）の中で、「データの部分」を区切るためのタグです。表は、通常、見出しやデータなどが含まれているのですが、tbodyタグを使うことで、データの部分を明確に分けることができます。これにより、表がもっと整理され、見やすくなるだけでなく、特に大きな表を作るときに便利です。tbodyタグは、通常、表の中のデータ行（つまり、trタグが入る場所）を囲むために使います。theadタグとtfootタグが、表の見出し部分やフッター（最後の部分）を担当するのに対し、tbodyタグはその間のメインのデータ部分を担当します。
        <a class="a" href="#<table>">※ サンプルはtableで確認</a>`
      }, {
        id: "<td>",
        title: "(テーブルの中の) データを入れる場所",
        description: `HTMLのtdタグは、表（テーブル）の中に「データを入れる場所」を作るためのタグです。ウェブページでテーブルを使って情報を整理する際に、このtdタグを使います。tdタグは、テーブルの中で「1つのセル」を作るためのタグです。テーブルは縦と横に並んだ線で囲まれた表のことですが、その表の中の1つ1つの「箱」のことを「セル」と言います。tdタグはそのセルにデータや文字を入れるために使います。テーブルを作るためには、まずtableタグで表を作り、その中に行を作るtrタグ、そしてその行の中にセルを作るtdタグを使います。
        <a class="a" href="#<table>">※ サンプルはtableで確認</a>`
      }, {
        id: "<th>",
        title: "(テーブルの中の) 見出しセル",
        description: `HTMLのthタグは、HTMLで作るテーブル（表）の中で、「見出し（ヘッダー）セル」を作るためのタグです。表には、データがたくさん並んでいますが、そのデータが何を意味しているのかを示すために「見出し」が必要です。この見出しを作るためにthタグを使います。thタグは、テーブルの中で「見出しセル」を作るためのタグです。通常、thタグは表の最初の行（見出しの行）や最初の列に使われます。見出しの部分にthタグを使うと、文字が太くなったり、中央に揃ったりして、データが何を意味しているのかがわかりやすくなります。thタグは、tableタグの中で、見出しを作りたいセルに使います。例えば、表の一番上の行に「名前」や「年齢」などの見出しを作り、その下にデータを並べることがよくあります。
        <a class="a" href="#<table>">※ サンプルはtableで確認</a>`,
      }, {
        id: "<thead>",
        title: "(テーブルの中の) 見出しの部分をまとめる",
        description: `HTMLのtheadタグは、HTMLで作る表（テーブル）の中で、「見出しの部分」をまとめるためのタグです。テーブルには「見出し」や「データ」などが含まれていますが、theadタグを使うと、テーブルの中で見出し部分がどこから始まるのかをはっきり示すことができます。 theadタグは、テーブルの中で「見出しの行」をまとめるためのタグです。通常、テーブルには複数の行がありますが、その中でも「見出しの行」は一番上にあり、他の行とは区別して表示されます。theadタグを使うと、見出し部分をきちんとグループ化することができ、特に大きな表では見やすく、整理された印象を与えることができます。theadタグは、trタグ（行）とその中のthタグ（見出しセル）を入れます。
        <a class="a" href="#<table>">※ サンプルはtableで確認</a>`,
      }, {
        id: "<tr>",
        title: "(テーブルの中の) 1つの行",
        description: `HTMLのtrタグは、HTMLで作るテーブル（表）の中で、「1つの行（行列の横の並び）」を作るためのタグです。テーブルは、縦（列）と横（行）でデータを並べますが、trタグはその「横」の並びを作るために使います。たとえば、名前や年齢、商品名などの情報を横に並べるときに、trタグで行を作ります。1行ごとにtrタグを使い、その中にデータ（tdタグ）や見出し（thタグ）を並べます。trタグは、テーブルの中に何行も作れます。
        <a class="a" href="#<table>">※ サンプルはtableで確認</a>`,
      }
    ],
  }, {
    title: "フォーム要素",
    list: [
      {
       id: "<button>",
        title: "ボタン",
        description: "<button>タグを記述すると、ボタンが表示されます。",
      }, {
        id: "<datalist>",
        title: "入力候補となるデータリスト",
        description: "HTMLのdatalistタグは、入力フォームで「選べる候補（選択肢）」を表示するためのタグです。これは、ユーザーが文字を入力し始めたときに、その入力にぴったり合う候補を表示して、選びやすくするために使います。例えば、名前や住所などを入力するフォームがあるとき、すでに入力されたことのある項目や予測される項目を選択肢として表示することで、ユーザーが早く正しく入力できるようにするための便利なタグです。",
      }, {
        id: "<form>",
        title: "入力・送信フォーム",
        description: "HTMLのformタグは、ウェブページで「情報を入力して送信するためのフォーム」を作るためのタグです。このタグを使うと、名前やメールアドレス、メッセージなど、ユーザーが情報を入力できるようになります。そして、入力された情報を送ることができるようになります。例えば、あなたがウェブサイトに名前やメールアドレスを入力して「送信」ボタンを押したとき、その情報がウェブサイトのサーバーに送られる仕組みを作るのがformタグです。", 
      }, {
        id: "<input>",
        title: "細長い入力欄",
        description: "フォーム入力欄を表す際に使用します。input要素は、type属性の値によって様々なフォームコントロールに変化します。",
      }, {
        id: "<label>",
        title: "ラベル",
        description: "フォーム入力欄のキャプションを表す。フォームの各入力欄となるユーザーインターフェイスのキャプションを表します。 label要素で指定するキャプションと、フォームの各入力欄を関連付けるには、2通りの方法があります。1つ目の方法は、label要素のfor属性の値と、 input要素、select要素、textarea要素などのフォーム部品のid名を同じものにする方法です。 label要素のfor属性の値とフォーム部品のid名が一致することで、 そのキャプションがどのフォーム入力欄のものなのかを関連付けます。2つ目の方法は、label要素自体の中にフォーム部品を配置することによって、 同じlabel要素内に記述されたキャプションとフォーム入力欄を関連付けます。2つの使用例で、キャプションとフォーム入力欄を関連付ける方法を2つ示しました。 どちらの場合にも、キャプションのテキスト部分をクリック（タップ）すると、関連するチェックボックスがcheckedの状態になります。 この挙動から、テキスト部分とチェックボックスが関連付けられていることが確認できます。"
      }, {
        id: "<option>",
        title: "セレクトボックスや入力候補リストの選択肢",
        description: "HTMLのoptionタグは、「選択肢」を作るためのタグです。主にselectタグと一緒に使われ、リストから1つの項目を選ぶためのアイテムを表示します。たとえば、ドロップダウンメニュー（選択肢が縦に並んだリスト）を作るときに使います。optionタグを使うと、ユーザーがリストの中から好きな項目を選べるようにすることができます。通常、optionタグはselectタグの中に書きます。selectタグは「選ぶリスト」を作るためのもので、その中にいくつかのoptionタグを入れることで、リストの中から1つを選ぶことができるようになります。",
      },
    ],
  }, {
    title: "テキストレベル要素",
    list: [
      {
        id: "<a>",
        title: "リンク(別のページに飛ぶ)",
        description: "HTMLのaタグは、「リンクを作るためのタグ」です。ウェブページの中で、別のページやサイト、あるいは同じページの別の場所に飛ぶことができるリンクを作るために使います。インターネットでどこか別のページに移動するとき、青い文字が下線付きで表示されている部分がaタグを使ったリンクになります。",
      }, {
        id: "<br>",
        title: "改行",
        description: "HTMLのbrタグは「改行」を意味するタグで、テキスト内に改行を挿入するために使用します。brタグは自己終了タグ（閉じタグが不要）で、単独で使います。これにより、次のテキストが新しい行に表示されます。",
      }, {
        id: "<i>",
        title: "斜体",
        description: "斜体にする効果があります。書籍のタイトルや外国語の単語を示す際に使用することが一般的です。強調や異なる声調を示すために用いられます。あまり使用頻度の高くないタグですが、「心の中の言葉」「ことわざ」「動植物の学名」など、他と区別したいときにも使われます。iタグは、アイコンフォントを使用する際にもよく使われます。※ ブラウザによってはイタリック体のスタイルがデフォルトではない(装飾されていない)ことがあります。",
      }, {
        id: "<span>",
        title: "インライン要素を作成(主にテキストの一部にスタイルを適用)",
        description: "spanタグは、HTMLの中で 「小さな範囲を指定するためのタグ」 です。これは、文の中の一部だけを特別に変更したり、他の部分とは違うスタイルをつけたりするときに使います。たとえば、文章の中で一部分だけ色を変えたり、太字にしたりする場合に便利です。",
      }
    ],
  }
];

// --------------------
//  Button script
// --------------------
const buttonCnt = ref(0);
function buttonAddFn() {
  buttonCnt.value++;
}
function buttonMinusFn() {
  if (buttonCnt.value <= 0) {
    return;
  }
  buttonCnt.value--;
}
function isDisabledButton() {
  return buttonCnt.value <= 0
}

// --------------------
//  
// --------------------
</script>

<style lang="scss">
</style>
