<template>
  <header>
    <nav class="print_hidden p-8 text-center">
      <router-link to="/">Home</router-link> |
      <router-link to="/html">HTML</router-link> |
      <router-link to="/cascading-style-sheets">CSS</router-link> |
      <router-link to="/javascript">JavaScript</router-link> |
      <router-link to="/vue">Vue</router-link>
    </nav>
  </header>
</template>

<style scoped>
header nav a {
  @apply font-bold text-[#2c3e50];
  &.router-link-exact-active {
    @apply text-[#42b983];
  }
}
</style>