<template>
  <Html />  
</template>

<script lang="ts">
import { defineComponent } from 'vue';

// Components
import Html from '@/components/views/HtmlComponent.vue';

export default defineComponent({
  components: {
    Html,
  },
});
</script>
