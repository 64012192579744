import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main01 reference javascript" }
const _hoisted_2 = { class: "examples" }
const _hoisted_3 = { class: "awaitasync-sample" }
const _hoisted_4 = { class: "create-element-sample" }
const _hoisted_5 = { class: "create-object-url-sample" }
const _hoisted_6 = { class: "profile-container" }
const _hoisted_7 = { id: "file-name" }
const _hoisted_8 = {
  id: "URL.revokeObjectURL",
  class: "example-wrap pdf_next_page_no"
}

import { ref } from "vue";
import { useStore } from 'vuex';
import Reference from "@/components/example/ReferenceComponents.vue";
import ExampleTemp from "@/components/example/ExampleTempComponents.vue";
import PdfNextPage from "@/components/PdfNextPage.vue";
// import Marker from "@/components/MarkerComponent.vue";

const alertCode0 = `  alert("アラートが表示されました。");`

const consoleCode0 = `  // JavaScriptのコード
  console.log("これはコンソールに表示されるメッセージです！");

  // もう少し複雑な例
  const name = "山田太郎";
  console.log("こんにちは、" + name + "さん！");

  // 数字の計算結果を表示
  const num = 10 + 20;
  console.log("10 + 20 は " + num + " です。");

  // エラーは赤丸×で表示
  console.error("エラーが発生しました");`

const awaitAsyncCode = `let asyncawaitTimer = 0;
function sleep(s: number) {
  return new Promise((resolve) => {
    const awaitasyncMessageElement = document.getElementById('awaitasync-message') as HTMLElement;

    const timerInc = 1000;
    const timeEnd = s*1000;
    const intervalId = setInterval(() => {
      if (null !== awaitasyncMessageElement) {
        awaitasyncMessageElement.textContent = "処理中です、ちょっと待ってね ... "+getAsyncAwaitSecond()+"秒";
      }
      if (0 === asyncawaitTimer) {
        console.log(2)
      }
      if (timeEnd === asyncawaitTimer) {
        if (null !== awaitasyncMessageElement) {
          awaitasyncMessageElement.textContent = "処理が完了しました。";
        }
        asyncawaitTimer = 0;
        clearInterval(intervalId)
        return resolve(true);
      }
      asyncawaitTimer += timerInc
    }, 1000)
  });
}
function getAsyncAwaitSecond(): string {
  return String(asyncawaitTimer/1000).replace(/[0-9a-zA-Z]/g, (char) =>
    String.fromCharCode(char.charCodeAt(0) + 0xFEE0)
  );
}
// asyncを使って、非同期処理を待つ関数
async function showAwaitAsync() {
  console.log(1)
  // awaitを使って、待っている間に他のことをしないようにする
  await sleep(5);
  console.log(3)
}`;

// ↓ ↓ ↓  上にコピペ
// ↓ ↓ ↓  上にコピペ
// ↓ ↓ ↓  上にコピペ
const createElementCode0 = `  // 新しいpタグを作る
  const newParagraph = document.createElement('p');
  // pタグの中に文字を入れる
  newParagraph.textContent = '★';
  // class="new-p"要素を取得
  const element = document.querySelector('.create-element-sample .new-p');
  // 作ったpタグを、id="new-p"要素に追加する
  if (null!==element) {
    element.appendChild(newParagraph);
  }`
const createObjectURLCode0 = `import { ref } from "vue";
const profileObjectURL = ref<string>("選択された画像のURLはありません、画像を選択してください。");
const profileObjectURLBackup = ref<null>(null);
function setProfileImage(obj) {
  const profileImageElement = document.getElementById('profile-image') as HTMLImageElement;
  if (null !== profileImageElement) {
    profileImageElement.src = obj;
    profileObjectURL.value = "生成されたブラウザ上のURL: "+obj;
  }
}
function onChangeCreateObjectURL(event: Event) {
  // --------------------
  // プロフィール画像を選択したときの裏側
  // --------------------
  if (event.target instanceof HTMLInputElement && null!==event.target.files) {
    const file: File = event.target.files[0]; // ユーザーが選択したファイル
    if (file) {
      // createObjectURLを使って、一時的なURLを作成
      const objectURL = URL.createObjectURL(file);
      // console.log(objectURL)
      profileObjectURLBackup.value = objectURL;
      // そのURLを画像のsrcに設定して、画像を表示
      setProfileImage(objectURL);
    }
  }
}
function onClickFlushObjectURL() {
  // 使用したURLを解放
  URL.revokeObjectURL(profileObjectURLBackup.value);
  // 再度設定すると画像設定失敗する。
  setProfileImage(profileObjectURLBackup.value);
  profileObjectURL.value = "選択された画像のURLはありません、画像を選択してください。";
}`;
// ↓ ↓ ↓  上にコピペ
// ↓ ↓ ↓  上にコピペ
// ↓ ↓ ↓  上にコピペ
// import { ref } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'JavascriptComponent',
  setup(__props) {

const store = useStore();
store.dispatch("highlightFalse");

//          <dl class="description-option">
const list = [
  {
    title: "関数",
    list: [
      {
        id: "alert",
        title: "画面にメッセージをポップアップで表示",
        description: "JavaScriptのalert()は、「画面にメッセージをポップアップで表示するための関数」です。ウェブページを操作しているときに、お知らせや注意書きを表示させるために使います。alert()は、小さなポップアップウィンドウを画面の中央に表示する関数です。その中にメッセージを表示して、ユーザーに伝えたいことを知らせることができます。たとえば、「このページを保存していません」とか、「確認してから進んでください」などのメッセージを表示するために使います。",
      }, {
        id: "console",
        title: "プログラムの中で情報の確認やデバッグ",
        description: "consoleは、プログラムの中で情報を確認したり、デバッグ（バグを見つけること）したりするために使うとても便利なツールです。consoleは、ブラウザの開発者ツールに出力するための特別なオブジェクトです。簡単に言うと、コンピュータの「メモ帳」みたいなもので、プログラムの動きを確認するためにメッセージを表示させることができます。consoleを使うと、プログラムがうまく動いているかどうかを簡単にチェックできます。これを使うことで、何が起きているのか、どこに問題があるのかを確認できます。", 
      }, {
        id: "await/async",
        title: "同期処理",
        description: "JavaScriptでは、何かの処理を順番に行うことが普通です。たとえば、1番目の仕事が終わってから、2番目の仕事を始める、というふうに処理が行われます。しかしインターネットからデータを取ってきたり、大きな計算をしたりするときには、少し時間がかかることがあります。そのため、実際の処理は順番通りにいかないものです。「待っている間に他のことをしたい」という場合に便利なのが、asyncとawaitという仕組みです。async は 「この関数は時間がかかるかもしれないことをする関数だよ」 という意味を持っていて、await は 「時間がかかることを待っている間、他のことをしてもいいよ」 という意味を持っています。"
      },
    ],
  }, {
    title: "documentオブジェクト",
    list: [
      {
        id: "document.createElement",
        title: "HTMLの新しいタグをJavaScriptで作るための関数",
        description: "document.createElementは、簡単に言うとページの中に新しい部品（要素）を作るための道具です。例えば、pタグを作って、その中に文字を入れ、ページに表示させることができます。これを使うと、最初からあるHTMLタグだけでなく、後から自分で作ったタグをページに追加できるので、動的に内容を変更したり、新しい情報を追加したりすることができるんです。",
      }
    ],
  }, {
    title: "URLオブジェクト",
    list: [
      {
        id: "URL.createObjectURL",
        title: "ブラウザ上で使うファイルのURLパスを生成",
        description: "URL.createObjectURLは、ブラウザ上でローカルのファイルを一時的にURL（インターネット上の住所みたいなもの）として扱うことができます。具体的に言うと、例えば自分のパソコンに保存している画像ファイルや動画ファイルを、ブラウザ内で「このファイルを使いたいよ！」という風に伝えるための特別なリンクを作ることができるんです。インターネット上のファイルは「URL」を使ってアクセスしますが、あなたのパソコンに保存されているファイルにはURLがありません。そこで、この「URL.createObjectURL」を使うことで、ブラウザがそのファイルを一時的に「ウェブ上で使えるURL」に変えてくれます。たとえばユーザ設定画面にあるプロフィール画像を変更する際、送信する前に選んだ画像を表示して確認できると親切です。",
      }, {
        id: "URL.revokeObjectURL",
        title: "URL.createObjectURLで作った一時的なURLを解放",
        description: `<p>URL.revokeObjectURLは、URL.createObjectURLで作った一時的なURLを無効にするために使います。URL.createObjectURL で、ローカルのファイルを一時的な「URL」に変えてブラウザに表示することができました。URL.revokeObjectURL は、そのURLがもう必要なくなったときに、ブラウザに「このURLはもう使わないから消してね！」と伝えるために使います。これを使うことで、ブラウザのメモリを無駄に使わないようにできます。例えば、もう表示した画像を消したり、ファイルのリンクが不要になった時にメモリを開放することができます。</p>
        <a class="a" href="#URL.createObjectURL">※ URL.createObjectURLで説明</a>
        `,
      },
    ],
  }
];


// const removeHtml = (v: string) => {
//   return v.replace(/<Marker>(.+?)<\/Marker>\n/s, (match, $1) => {
//     return "<span class='hl'>"+$1+"</span>";
//   })
// }


// --------------------
//  alert
// --------------------
const showAlert0 = `function showAlert() {
${alertCode0}
}`;

function showAlert() {
  eval(alertCode0)
}

// --------------------
//  console
// --------------------
const showConsole0 = `function showConsole() {
${consoleCode0}
}`

function showConsole() {
  eval(consoleCode0)
}

// --------------------
//  await async
// --------------------
// xxxx ※ awaitは上位層じゃないと使えない。
// xxxx function showAwaitAsync() {
// xxxx   eval(removeHtml(awaitAsyncCode))
// xxxx }

let asyncawaitTimer = 0;
function sleep(s: number) {
  return new Promise((resolve) => {
    const awaitasyncMessageElement = document.getElementById('awaitasync-message') as HTMLElement;

    const timerInc = 1000;
    const timeEnd = s*1000;
    const intervalId = setInterval(() => {
      if (null !== awaitasyncMessageElement) {
        awaitasyncMessageElement.textContent = "処理中です、ちょっと待ってね ... "+getAsyncAwaitSecond()+"秒";
      }
      if (0 === asyncawaitTimer) {
        console.log(2)
      }
      if (timeEnd === asyncawaitTimer) {
        if (null !== awaitasyncMessageElement) {
          awaitasyncMessageElement.textContent = "処理が完了しました。";
        }
        asyncawaitTimer = 0;
        clearInterval(intervalId)
        return resolve(true);
      }
      asyncawaitTimer += timerInc
    }, 1000)
  });
}
function getAsyncAwaitSecond(): string {
  return String(asyncawaitTimer/1000).replace(/[0-9a-zA-Z]/g, (char) =>
    String.fromCharCode(char.charCodeAt(0) + 0xFEE0)
  );
}
// asyncを使って、非同期処理を待つ関数
async function showAwaitAsync() {
  console.log(1)
  // awaitを使って、待っている間に他のことをしないようにする
  await sleep(5);
  console.log(3)
}
// ↑ ↑ ↑ 上にコピペ
// ↑ ↑ ↑ 上にコピペ
// ↑ ↑ ↑ 上にコピペ

// --------------------
//  document.createElement
// --------------------
const onClickCreateElement0 = `function onClickCreateElement() {
${createElementCode0}
}`

function onClickCreateElement() {
  eval(createElementCode0)
}

// --------------------
//   createObjectURL
// --------------------
//  document.getElementById('image-upload').addEventListener('change', function(event) {
const profileObjectURL = ref<string>("選択された画像のURLはありません、画像を選択してください。");
const profileObjectURLBackup = ref<null>(null);
function setProfileImage(obj) {
  const profileImageElement = document.getElementById('profile-image') as HTMLImageElement;
  if (null !== profileImageElement) {
    profileImageElement.src = obj;
    profileObjectURL.value = "生成されたブラウザ上のURL: "+obj;
  }
}
function onChangeCreateObjectURL(event: Event) {
  // --------------------
  // プロフィール画像を選択したときの裏側
  // --------------------
  if (event.target instanceof HTMLInputElement && null!==event.target.files) {
    const file: File = event.target.files[0]; // ユーザーが選択したファイル
    if (file) {
      // createObjectURLを使って、一時的なURLを作成
      const objectURL = URL.createObjectURL(file);
      // console.log(objectURL)
      profileObjectURLBackup.value = objectURL;
      // そのURLを画像のsrcに設定して、画像を表示
      setProfileImage(objectURL);
    }
  }
}
function onClickFlushObjectURL() {
  // 使用したURLを解放
  URL.revokeObjectURL(profileObjectURLBackup.value);
  // 再度設定すると画像設定失敗する。
  setProfileImage(profileObjectURLBackup.value);
  profileObjectURL.value = "選択された画像のURLはありません、画像を選択してください。";
}
// ↑ ↑ ↑ 上にコピペ
// ↑ ↑ ↑ 上にコピペ
// ↑ ↑ ↑ 上にコピペ

return (_ctx: any,_cache: any) => {
  const _component_my_code_only = _resolveComponent("my-code-only")!
  const _component_my_show_only = _resolveComponent("my-show-only")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[67] || (_cache[67] = _createElementVNode("h1", { class: "midasi text-xl" }, "JavaScript リファレンス", -1)),
    _createVNode(Reference, { list: list }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(ExampleTemp, {
        id: 'alert',
        list: list,
        pdfNextPageShow: true,
        isnobrowsertitle: true
      }, {
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[9] || (_cache[9] = _createTextVNode("")),
            _createElementVNode("div", null, [
              _createVNode(_component_my_code_only, null, {
                default: _withCtx(() => _cache[4] || (_cache[4] = [
                  _createTextVNode("\n  <button class=\"btn btn-white\" @click=\"showAlert()\">クリックするとアラートが表示されます。</button>")
                ])),
                _: 1
              }),
              _cache[7] || (_cache[7] = _createTextVNode("\n")),
              _createVNode(_component_my_show_only, null, {
                default: _withCtx(() => [
                  _cache[5] || (_cache[5] = _createTextVNode("\n  ")),
                  _createElementVNode("button", {
                    class: "btn btn-white",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (showAlert()))
                  }, "クリックするとアラートが表示されます。"),
                  _cache[6] || (_cache[6] = _createTextVNode("\n"))
                ]),
                _: 1
              }),
              _cache[8] || (_cache[8] = _createTextVNode("\n\n"))
            ]),
            _cache[10] || (_cache[10] = _createTextVNode("\n"))
          ])
        ]),
        script: _withCtx(() => [
          _createElementVNode("pre", null, "" + _toDisplayString(showAlert0) + "\n")
        ]),
        _: 1
      }),
      _createVNode(ExampleTemp, {
        id: 'console',
        list: list,
        pdfNextPageShow: true,
        isnobrowsertitle: true
      }, {
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[16] || (_cache[16] = _createTextVNode("")),
            _cache[17] || (_cache[17] = _createElementVNode("div", null, "\n  ブラウザの開発者ツールを開いて(Ctrl + Shift + i)、コンソール(Console)を見てください。\n", -1)),
            _cache[18] || (_cache[18] = _createTextVNode("\n")),
            _createElementVNode("div", null, [
              _createVNode(_component_my_code_only, null, {
                default: _withCtx(() => _cache[11] || (_cache[11] = [
                  _createTextVNode("\n  <button class=\"btn btn-white\" @click=\"showConsole()\">クリックするとコンソールに表示されます。</button>\n")
                ])),
                _: 1
              }),
              _cache[14] || (_cache[14] = _createTextVNode("\n")),
              _createVNode(_component_my_show_only, null, {
                default: _withCtx(() => [
                  _cache[12] || (_cache[12] = _createTextVNode("\n  ")),
                  _createElementVNode("button", {
                    class: "btn btn-white",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (showConsole()))
                  }, "クリックするとコンソールに表示されます。"),
                  _cache[13] || (_cache[13] = _createTextVNode("\n"))
                ]),
                _: 1
              }),
              _cache[15] || (_cache[15] = _createTextVNode("\n"))
            ]),
            _cache[19] || (_cache[19] = _createTextVNode("\n"))
          ])
        ]),
        script: _withCtx(() => [
          _createElementVNode("pre", null, "" + _toDisplayString(showConsole0) + "\n")
        ]),
        _: 1
      }),
      _createVNode(ExampleTemp, {
        id: 'await/async',
        list: list,
        pdfNextPageShow: true,
        isnobrowsertitle: true
      }, {
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[31] || (_cache[31] = _createTextVNode("")),
            _createElementVNode("div", _hoisted_3, [
              _cache[25] || (_cache[25] = _createTextVNode("\n  ")),
              _cache[26] || (_cache[26] = _createElementVNode("div", { id: "awaitasync-message" }, null, -1)),
              _cache[27] || (_cache[27] = _createTextVNode("\n  ")),
              _createElementVNode("div", null, [
                _createVNode(_component_my_code_only, null, {
                  default: _withCtx(() => _cache[20] || (_cache[20] = [
                    _createTextVNode("\n    <button class=\"btn btn-white\" @click=\"showAwaitAsync()\">クリックすると５秒後に処理が終了します。</button>")
                  ])),
                  _: 1
                }),
                _cache[23] || (_cache[23] = _createTextVNode("\n")),
                _createVNode(_component_my_show_only, null, {
                  default: _withCtx(() => [
                    _cache[21] || (_cache[21] = _createTextVNode("\n    ")),
                    _createElementVNode("button", {
                      class: "btn btn-white",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (showAwaitAsync()))
                    }, "クリックすると５秒後に処理が終了します。"),
                    _cache[22] || (_cache[22] = _createTextVNode("\n"))
                  ]),
                  _: 1
                }),
                _cache[24] || (_cache[24] = _createTextVNode("\n\n  "))
              ]),
              _cache[28] || (_cache[28] = _createTextVNode("\n  ")),
              _cache[29] || (_cache[29] = _createElementVNode("div", null, [
                _createTextVNode("\n    "),
                _createElementVNode("span", null, "※ コンソールに処理される順番が表示されます。"),
                _createTextVNode("\n  ")
              ], -1)),
              _cache[30] || (_cache[30] = _createTextVNode("\n"))
            ]),
            _cache[32] || (_cache[32] = _createTextVNode("\n"))
          ])
        ]),
        script: _withCtx(() => [
          _createElementVNode("pre", null, "" + _toDisplayString(awaitAsyncCode) + "\n")
        ]),
        style: _withCtx(() => _cache[33] || (_cache[33] = [
          _createElementVNode("pre", null, ".awaitasync-sample {\n  #awaitasync-message {\n    margin-bottom: 16px;\n  }\n  span {\n    font-size: 12px;\n  }\n}\n", -1)
        ])),
        _: 1
      }),
      _createVNode(ExampleTemp, {
        id: 'document.createElement',
        list: list,
        pdfNextPageShow: true,
        isnobrowsertitle: true
      }, {
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[45] || (_cache[45] = _createTextVNode("")),
            _createElementVNode("div", _hoisted_4, [
              _cache[39] || (_cache[39] = _createTextVNode("\n  ")),
              _cache[40] || (_cache[40] = _createElementVNode("div", null, "以下はJavaScriptコードから生成されたHTMLタグです。", -1)),
              _cache[41] || (_cache[41] = _createTextVNode("\n  ")),
              _cache[42] || (_cache[42] = _createElementVNode("div", { class: "new-p" }, null, -1)),
              _cache[43] || (_cache[43] = _createTextVNode("\n  ")),
              _createElementVNode("div", null, [
                _createVNode(_component_my_code_only, null, {
                  default: _withCtx(() => _cache[34] || (_cache[34] = [
                    _createTextVNode("\n    <button class=\"btn btn-white\" @click=\"onClickCreateElement()\">クリックすると★が増えていきます。</button>")
                  ])),
                  _: 1
                }),
                _cache[37] || (_cache[37] = _createTextVNode("\n")),
                _createVNode(_component_my_show_only, null, {
                  default: _withCtx(() => [
                    _cache[35] || (_cache[35] = _createTextVNode("\n  ")),
                    _createElementVNode("button", {
                      class: "btn btn-white",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (onClickCreateElement()))
                    }, "クリックすると★が増えていきます。"),
                    _cache[36] || (_cache[36] = _createTextVNode("\n"))
                  ]),
                  _: 1
                }),
                _cache[38] || (_cache[38] = _createTextVNode("\n\n  "))
              ]),
              _cache[44] || (_cache[44] = _createTextVNode("\n"))
            ]),
            _cache[46] || (_cache[46] = _createTextVNode("\n"))
          ])
        ]),
        script: _withCtx(() => [
          _createElementVNode("pre", null, "" + _toDisplayString(onClickCreateElement0) + "\n")
        ]),
        style: _withCtx(() => _cache[47] || (_cache[47] = [
          _createElementVNode("pre", null, ".create-element-sample {\n  .new-p {\n    background: white;\n    margin: 15px auto;\n    width: 200px;\n    min-height: 50px;\n    p {\n      text-align: center;\n    }\n  }\n}\n", -1)
        ])),
        _: 1
      }),
      _createVNode(ExampleTemp, {
        id: 'URL.createObjectURL',
        list: list,
        pdfNextPageShow: true,
        isnobrowsertitle: true
      }, {
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[64] || (_cache[64] = _createTextVNode("")),
            _createElementVNode("div", _hoisted_5, [
              _cache[62] || (_cache[62] = _createTextVNode("\n  ")),
              _createElementVNode("div", _hoisted_6, [
                _cache[53] || (_cache[53] = _createTextVNode("\n    ")),
                _cache[54] || (_cache[54] = _createElementVNode("h3", null, "プロフィール画像変更の例", -1)),
                _cache[55] || (_cache[55] = _createTextVNode("\n    \n    ")),
                _cache[56] || (_cache[56] = _createElementVNode("div", { class: "profile-image-container" }, [
                  _createTextVNode("\n      "),
                  _createElementVNode("img", {
                    id: "profile-image",
                    src: "/profile.jpg",
                    alt: "プロフィール画像",
                    class: "profile-image"
                  }),
                  _createTextVNode("\n    ")
                ], -1)),
                _cache[57] || (_cache[57] = _createTextVNode("\n    ")),
                _createVNode(_component_my_code_only, null, {
                  default: _withCtx(() => _cache[48] || (_cache[48] = [
                    _createTextVNode("\n    <input type=\"file\" id=\"image-upload\" accept=\"image/*\" @change=\"onChangeCreateObjectURL\">")
                  ])),
                  _: 1
                }),
                _cache[58] || (_cache[58] = _createTextVNode("\n")),
                _createVNode(_component_my_show_only, null, {
                  default: _withCtx(() => [
                    _cache[49] || (_cache[49] = _createTextVNode("\n    ")),
                    _createElementVNode("input", {
                      type: "file",
                      id: "image-upload",
                      accept: "image/*",
                      onChange: onChangeCreateObjectURL
                    }, null, 32),
                    _cache[50] || (_cache[50] = _createTextVNode("\n"))
                  ]),
                  _: 1
                }),
                _cache[59] || (_cache[59] = _createTextVNode("\n\n    \n    ")),
                _createElementVNode("div", _hoisted_7, _toDisplayString(profileObjectURL.value), 1),
                _cache[60] || (_cache[60] = _createTextVNode("\n    ")),
                _createElementVNode("div", { class: "flush-wrap" }, [
                  _cache[51] || (_cache[51] = _createTextVNode("\n      ")),
                  _createElementVNode("button", {
                    class: "btn btn-white",
                    onClick: onClickFlushObjectURL
                  }, "一時的なURLを解放する"),
                  _cache[52] || (_cache[52] = _createTextVNode("\n    "))
                ]),
                _cache[61] || (_cache[61] = _createTextVNode("\n  "))
              ]),
              _cache[63] || (_cache[63] = _createTextVNode("\n"))
            ]),
            _cache[65] || (_cache[65] = _createTextVNode("\n"))
          ])
        ]),
        script: _withCtx(() => [
          _createElementVNode("pre", null, "" + _toDisplayString(createObjectURLCode0) + "\n")
        ]),
        style: _withCtx(() => _cache[66] || (_cache[66] = [
          _createElementVNode("pre", null, ".create-object-url-sample {\n  .profile-container {\n    margin-left: auto;\n    margin-right: auto;\n    text-align: center;\n    padding: 20px;\n    background-color: #fff;\n    border-radius: 10px;\n    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);\n    width: 100%;\n    @media (min-width: 400px) {\n      width: 300px;\n    }\n  }\n  .profile-image-container {\n    margin-bottom: 20px;\n    text-align: center;\n  }\n  .profile-image {\n    width: 100px;\n    height: 100px;\n    margin-left: auto;\n    margin-right: auto;\n    border-radius: 50%;\n    object-fit: cover;\n    margin-bottom: 10px;\n  }\n  input[type=\"file\"] {\n    font-size: 0.8rem;\n    margin: 10px 0;\n  }\n  #file-name {\n    color: #777;\n    font-size: 14px;\n  }\n  .flush-wrap {\n    margin-top: 16px;\n  }\n}\n", -1)
        ])),
        _: 1
      }),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(ExampleTemp, {
          id: 'URL.revokeObjectURL',
          list: list,
          pdfNextPageShow: true,
          isnoexample: true
        })
      ]),
      _createVNode(PdfNextPage)
    ])
  ]))
}
}

})