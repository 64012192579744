<template>
<pre class="base">
<div>
  <div>おこずかい</div><my-code-only-2>
  <div v-pre><span class="highlight">{{props.presentToChild}}</span></div>
</my-code-only-2>
<my-show-only-2>
  <div><span class="highlight">{{props.presentToChild}}</span></div>
</my-show-only-2>
</div>
</pre>
</template>

<script setup>
import { defineProps } from 'vue';

const props = defineProps({
  presentToChild: { type: String, default: "あめ" }
});
</script>
