import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main01 reference vue" }
const _hoisted_2 = { class: "examples" }
const _hoisted_3 = { class: "mb-2" }
const _hoisted_4 = { class: "highlight" }
const _hoisted_5 = { class: "use-router-sample" }
const _hoisted_6 = { class: "buttons" }
const _hoisted_7 = { class: "highlight" }
const _hoisted_8 = {
  id: "note",
  class: "note"
}
const _hoisted_9 = { class: "h2-list" }

import { ref } from "vue";
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Reference from "@/components/example/ReferenceComponents.vue";
import ExampleTemp from "@/components/example/ExampleTempComponents.vue";
import PdfNextPage from "@/components/PdfNextPage.vue";
import Marker from "@/components/MarkerComponent.vue";
import Child from "@/components/sample/ChildComponentOfDefineProps.vue";
import Code from "@/components/CodeComponent.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'VueComponent',
  setup(__props) {

const refObj = ref("");

const router = useRouter();

const store = useStore();
store.dispatch("highlightFalse");

const list = [
  {
    title: "関数",
    list: [
      {
        id: "defineProps",
        title: "親から渡されたプロパティ（props）を定義",
        description: "コンポーネントが受け取るプロパティ（props）を明示的に定義および取得し、型チェックやデフォルト値の設定も行える関数",
      }, {
        id: "ref",
        title: "リアクティブなデータを扱うための関数",
        description: "refを使うことで、単一の値をリアクティブにすることができます。リアクティブな値とは、値が変更されたときに自動的にUIが更新される値のことです。①でrefメソッドをインポートしたあと、②でデータオブジェクトの宣言をします。その際、refメソッドで修飾することでリアクティブな状態になります。リアクティブとは値の変化を監視するための機能です。リアクティブ化された変数のことをRef変数と呼びます。Ref変数を&lt;template&gt;内で使うと値が更新された際、自動で変更されます。const, let, varで作られた変数は自動で変更されないので注意が必要です。",
      }, {
        id: "useRouter",
        title: "Routerのインスタンスにアクセスするための関数",
        description: `<p>この関数を使うことで、プログラム的にルートの遷移を管理したり、ナビゲーションガードを設定したりすることができます。例えば、ホームページに遷移する場合は以下のように使います。またページの移動には&lt;router-link&gt;要素も使えます。router.push()は&lt;script&gt;要素内に記述された複雑なコード内での移動、&lt;router-link&gt;は&lt;template&gt;要素内で簡易的に移動するのが一般的です。</p>
          <small>パス情報に指定できる主なパラメータ</small>
          <dl class="description-option">
            <dt>params ... ルートパラメーター</dt>
            <dt>query ... クエリ情報</dt>
            <dt>hash ... ハッシュ情報</dt>
            <dt>meta ... メタ情報</dt>
          </dl>
          ※ こちらの例は、手順１です。手順２は<a href="#useRoute">&lt;useRoute&gt;</a>を参照ください。<br />`
      }, {
        id: "useRoute",
        title: "現在のアクティブなルートに関する情報を取得するための関数",
        description: `<p>この関数を使うことで、現在のURLのパス、ルートパラメータ、クエリパラメータなどにアクセスできます。</p>
          <dl class="description-option">
            <dt>params ... ルートパラメータ</dt>
            <dd>
              route.params.id
              → 0001
            </dd>
            <dt>params ... クエリ情報</dt>
            <dd>
              route.query.name
              → taro
            </dd>
            <dt>path ... ルートのパス</dt>
            <dd>
              route.path
              → /javascript/0001
            </dd>
            <dt>fullPath ... クエリ/ハッシュを含んだ完全なパス</dt>
            <dd>
              route.fullPath
              → /javascript/0001#hash?num=1
            </dd>
          </dl>
          例えば、現在のルートからパラメータまたはクエリパラメータを取得する場合は以下のように使います。<br />
          ※ こちらの例は、手順２です。手順１は<a class="a" href="#useRouter">&lt;useRouter&gt;</a>を参照ください。`,
      }, {
        id: "useStore",
        title: "Vuexストアにアクセスするための関数",
        description: "Vue.jsのストア（store）は、アプリケーション全体の状態（state）を管理するための集中型のコンテナです。Vuexというライブラリを使って実装されることが一般的です。ストアを使うことで、コンポーネント間で状態を共有しやすくなり、アプリケーションの状態管理がシンプルかつ予測可能になります。Vuexそのものは簡易的なストアにすぎません、容れ物を準備してそこに値を出し入れするだけのライブラリです。この例では、useStoreを使ってストアにアクセスし、countというステートとincrementというミューテーションを取得して変数に設定しています。useStoreを使うことで、Vuexストアのステートやゲッター、ミューテーション、アクションに簡単にアクセスでき、Composition APIの利点を活かした柔軟な状態管理が可能になります。",
      }
    ],
  }
]

function gotoHome() {
  router.push('/home');
}
function gotoGirlFromParams() {
  router.push({ name: 'Girlfriend', params: { present: 'ディズニーランド ホテル(ウォールトディズニー) 無料宿泊券' } }); 
}
function gotoGirlFromQuery() {
  router.push({ name: 'Girlfriend', query: { present: 'ディズニーシー ホテル(ミラコスタ) 無料宿泊券' } }); 
}

// const plus2 = () => store.commit('plus', 2);
// const increment = () => store.commit('increment');
// const count = computed(() => store.getters.count);
const count = ref(store.getters.getCount);

const increment = () => {
  count.value++;
  store.commit('increment');
  // store.dispatch('increment');
}


// let letTime = new Date().toISOString();
// const refTime = ref(new Date().toISOString());
// function refClick() {
//   const now = new Date().toISOString()
//   letTime = now;
//   refTime.value = now;
//   console.log(letTime)
// }

return (_ctx: any,_cache: any) => {
  const _component_my_show_only = _resolveComponent("my-show-only")!
  const _component_my_code_only = _resolveComponent("my-code-only")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[134] || (_cache[134] = _createElementVNode("h1", { class: "midasi text-xl" }, "Vue.js 3 リファレンス", -1)),
    _createVNode(Reference, { list: list }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(ExampleTemp, {
        id: 'defineProps',
        list: list,
        pdfNextPageShow: true,
        isnoshow: true
      }, {
        codeTitle: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode(" @/components/sample/ChildComponentOfDefineProps.vue ")
        ])),
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[6] || (_cache[6] = _createTextVNode("")),
            _createVNode(Child, { presentToChild: "520円" }),
            _cache[7] || (_cache[7] = _createTextVNode("\n"))
          ])
        ]),
        script: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[10] || (_cache[10] = _createTextVNode("")),
            _createVNode(Marker, null, {
              default: _withCtx(() => _cache[8] || (_cache[8] = [
                _createTextVNode("import { defineProps } from 'vue';")
              ])),
              _: 1
            }),
            _cache[11] || (_cache[11] = _createTextVNode("\n\n")),
            _createVNode(Marker, null, {
              default: _withCtx(() => _cache[9] || (_cache[9] = [
                _createTextVNode("const props = defineProps({\n  presentToChild: { type: String, default: \"あめ\" }\n});")
              ])),
              _: 1
            }),
            _cache[12] || (_cache[12] = _createTextVNode("\n"))
          ])
        ]),
        _: 1
      }),
      _createVNode(ExampleTemp, null, {
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[13] || (_cache[13] = _createTextVNode("")),
            _cache[14] || (_cache[14] = _createElementVNode("my-code-only", null, [
              _createElementVNode("Child", { presentToChild: "520円" })
            ], -1)),
            _cache[15] || (_cache[15] = _createTextVNode("\n")),
            _createVNode(_component_my_show_only, null, {
              default: _withCtx(() => [
                _createVNode(Child, { presentToChild: "520円" })
              ]),
              _: 1
            }),
            _cache[16] || (_cache[16] = _createTextVNode("\n"))
          ])
        ]),
        script: _withCtx(() => _cache[17] || (_cache[17] = [
          _createElementVNode("pre", null, "import Child from \"@/components/sample/ChildComponentOfDefineProps.vue\";\n", -1)
        ])),
        _: 1
      }),
      _createVNode(ExampleTemp, {
        id: 'ref',
        list: list,
        pdfNextPageShow: true
      }, {
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[27] || (_cache[27] = _createTextVNode("  ")),
            _createElementVNode("div", _hoisted_3, [
              _cache[18] || (_cache[18] = _createTextVNode("\n    ")),
              _cache[19] || (_cache[19] = _createElementVNode("my-code-only", null, [
                _createElementVNode("input", {
                  type: "text",
                  "v-model": "refObj"
                })
              ], -1)),
              _cache[20] || (_cache[20] = _createTextVNode("\n")),
              _createVNode(_component_my_show_only, null, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("input", {
                    type: "text",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((refObj).value = $event))
                  }, null, 512), [
                    [_vModelText, refObj.value]
                  ])
                ]),
                _: 1
              }),
              _cache[21] || (_cache[21] = _createTextVNode("\n\n  "))
            ]),
            _cache[28] || (_cache[28] = _createTextVNode("\n  ")),
            _createElementVNode("div", null, [
              _cache[25] || (_cache[25] = _createTextVNode("\n    ただいまRef変数の値は「 ")),
              _createElementVNode("span", _hoisted_4, [
                _cache[22] || (_cache[22] = _createElementVNode("my-code-only", null, "{{\"\"===refObj ? \"空\" : refObj}}", -1)),
                _cache[23] || (_cache[23] = _createTextVNode("\n")),
                _createVNode(_component_my_show_only, null, {
                  default: _withCtx(() => [
                    _createTextVNode("\n      " + _toDisplayString(""===refObj.value ? "空" : refObj.value) + "\n    ", 1)
                  ]),
                  _: 1
                }),
                _cache[24] || (_cache[24] = _createTextVNode("\n"))
              ]),
              _cache[26] || (_cache[26] = _createTextVNode(" 」です。\n  "))
            ]),
            _cache[29] || (_cache[29] = _createTextVNode("\n"))
          ])
        ]),
        script: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[32] || (_cache[32] = _createTextVNode("// ① refメソッドをインポート\n")),
            _createVNode(Marker, null, {
              default: _withCtx(() => _cache[30] || (_cache[30] = [
                _createTextVNode("import { ref } from \"vue\";")
              ])),
              _: 1
            }),
            _cache[33] || (_cache[33] = _createTextVNode("\n// ② Refオブジェクトを作成(空の文字列で初期化)\n")),
            _createVNode(Marker, null, {
              default: _withCtx(() => _cache[31] || (_cache[31] = [
                _createTextVNode("const refObj = ref(\"\");")
              ])),
              _: 1
            }),
            _cache[34] || (_cache[34] = _createTextVNode("\n"))
          ])
        ]),
        style: _withCtx(() => _cache[35] || (_cache[35] = [
          _createElementVNode("pre", null, ".mb-2 {\n  margin-bottom: 0.5rem; /* 8px */\n}\n", -1)
        ])),
        _: 1
      }),
      _createVNode(ExampleTemp, {
        id: 'useRouter',
        list: list,
        pdfNextPageShow: true
      }, {
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[54] || (_cache[54] = _createTextVNode("")),
            _createElementVNode("div", _hoisted_5, [
              _cache[50] || (_cache[50] = _createTextVNode("\n  ")),
              _createElementVNode("div", null, [
                _createVNode(_component_my_code_only, null, {
                  default: _withCtx(() => _cache[36] || (_cache[36] = [
                    _createTextVNode("\n    <button class=\"btn btn-white highlight\" @click=\"gotoHome()\">スクリプトからホームページに遷移</button>\n")
                  ])),
                  _: 1
                }),
                _cache[39] || (_cache[39] = _createTextVNode("\n")),
                _createVNode(_component_my_show_only, null, {
                  default: _withCtx(() => [
                    _cache[37] || (_cache[37] = _createTextVNode("\n  ")),
                    _createElementVNode("button", {
                      class: "btn btn-white highlight",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (gotoHome()))
                    }, "スクリプトからホームページに遷移"),
                    _cache[38] || (_cache[38] = _createTextVNode("\n"))
                  ]),
                  _: 1
                }),
                _cache[40] || (_cache[40] = _createTextVNode("\n  "))
              ]),
              _cache[51] || (_cache[51] = _createTextVNode("\n  ")),
              _createElementVNode("div", null, [
                _cache[42] || (_cache[42] = _createTextVNode("\n    ")),
                _createVNode(_component_router_link, {
                  class: "a highlight",
                  to: "/"
                }, {
                  default: _withCtx(() => _cache[41] || (_cache[41] = [
                    _createTextVNode("テンプレートからホームページに遷移")
                  ])),
                  _: 1
                }),
                _cache[43] || (_cache[43] = _createTextVNode("\n  "))
              ]),
              _cache[52] || (_cache[52] = _createTextVNode("\n  ")),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_my_code_only, null, {
                  default: _withCtx(() => _cache[44] || (_cache[44] = [
                    _createTextVNode("\n    <button class=\"btn btn-white highlight\" @click=\"gotoGirlFromParams()\">（パラメータ経由で）花子にプレゼントを渡す</button>\n    <button class=\"btn btn-white highlight\" @click=\"gotoGirlFromQuery()\">（クエリ経由で）花子にプレゼントを渡す</button>")
                  ])),
                  _: 1
                }),
                _cache[48] || (_cache[48] = _createTextVNode("\n")),
                _createVNode(_component_my_show_only, null, {
                  default: _withCtx(() => [
                    _cache[45] || (_cache[45] = _createTextVNode("\n    ")),
                    _createElementVNode("button", {
                      class: "btn btn-white highlight",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => (gotoGirlFromParams()))
                    }, "（パラメータ経由で）花子にプレゼントを渡す"),
                    _cache[46] || (_cache[46] = _createTextVNode("\n    ")),
                    _createElementVNode("button", {
                      class: "btn btn-white highlight",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => (gotoGirlFromQuery()))
                    }, "（クエリ経由で）花子にプレゼントを渡す"),
                    _cache[47] || (_cache[47] = _createTextVNode("\n"))
                  ]),
                  _: 1
                }),
                _cache[49] || (_cache[49] = _createTextVNode("\n\n  "))
              ]),
              _cache[53] || (_cache[53] = _createTextVNode("\n"))
            ]),
            _cache[55] || (_cache[55] = _createTextVNode("\n"))
          ])
        ]),
        script: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[60] || (_cache[60] = _createTextVNode("")),
            _createVNode(Marker, null, {
              default: _withCtx(() => _cache[56] || (_cache[56] = [
                _createTextVNode("// ① useRouterメソッドをインポート\nimport { useRouter } from 'vue-router';\n// ② Routerオブジェクトを作成\nconst router = useRouter();\n")
              ])),
              _: 1
            }),
            _cache[61] || (_cache[61] = _createTextVNode("\nfunction gotoHome() {\n")),
            _createVNode(Marker, null, {
              default: _withCtx(() => _cache[57] || (_cache[57] = [
                _createTextVNode("  // ③ Homeページxに遷移\n  router.push('/home');")
              ])),
              _: 1
            }),
            _cache[62] || (_cache[62] = _createTextVNode("\n}\nfunction gotoGirlFromParams() {\n")),
            _createVNode(Marker, null, {
              default: _withCtx(() => _cache[58] || (_cache[58] = [
                _createTextVNode("  // ③ Girlfriendページにパラメータを設定して遷移\n  // http://localhost:8080/girlfriend/ディズニーランド ホテル(ウォールトディズニー) 無料宿泊券\nrouter.push({\n    name: 'Girlfriend',\n    params: { present: 'ディズニーランド ホテル(ウォールトディズニー) 無料宿泊券'\n  }});")
              ])),
              _: 1
            }),
            _cache[63] || (_cache[63] = _createTextVNode("\n}\nfunction gotoGirlFromQuery() {\n")),
            _createVNode(Marker, null, {
              default: _withCtx(() => _cache[59] || (_cache[59] = [
                _createTextVNode("  // ③ Girlfriendページにクエリを付けて遷移\n  // http://localhost:8080/girlfriend?present=ディズニーシー ホテル(ミラコスタ) 無料宿泊券\nrouter.push({\n    name: 'Girlfriend',\n    query: { present: 'ディズニーシー ホテル(ミラコスタ) 無料宿泊券' }\n  });")
              ])),
              _: 1
            }),
            _cache[64] || (_cache[64] = _createTextVNode("\n}\n"))
          ])
        ]),
        style: _withCtx(() => _cache[65] || (_cache[65] = [
          _createElementVNode("pre", null, ".use-router-sample {\n  margin-bottom: 1.5rem !important; /* 24px */\n}\n.buttons {\n  button:first-child {\n    margin-bottom: 0.5rem !important; /* 8px */\n  }\n}\n", -1)
        ])),
        _: 1
      }),
      _createVNode(ExampleTemp, {
        id: 'useRoute',
        list: list,
        pdfNextPageShow: true,
        isnoshow: true,
        isjscode: true
      }, {
        codeTitle: _withCtx(() => _cache[66] || (_cache[66] = [
          _createTextVNode(" @/router/index.js ")
        ])),
        script: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[69] || (_cache[69] = _createTextVNode("import { createRouter, createWebHistory } from 'vue-router';\nimport UseRouteSampleGirlfriendView from '@/views/UseRouteSampleGirlfriendView.vue';\n\nconst routes = [\n  {\n    ...\n  }, {\n    ")),
            _createVNode(Marker, null, {
              default: _withCtx(() => _cache[67] || (_cache[67] = [
                _createTextVNode("path: '/girlfriend/:present*',")
              ])),
              _: 1
            }),
            _cache[70] || (_cache[70] = _createTextVNode("\n    ")),
            _createVNode(Marker, null, {
              default: _withCtx(() => _cache[68] || (_cache[68] = [
                _createTextVNode("name: 'Girlfriend',")
              ])),
              _: 1
            }),
            _cache[71] || (_cache[71] = _createTextVNode("\n    component: UseRouteSampleGirlfriendView\n  }, {\n    ...\n  }\n];\nconst router = createRouter({\n  history: createWebHistory(process.env.BASE_URL),\n  routes\n});\nexport default router;\n"))
          ])
        ]),
        _: 1
      }),
      _createVNode(ExampleTemp, null, {
        codeTitle: _withCtx(() => _cache[72] || (_cache[72] = [
          _createTextVNode(" @/views/UseRouteSampleGirlfriendView.vue ")
        ])),
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[74] || (_cache[74] = _createTextVNode("")),
            _cache[75] || (_cache[75] = _createElementVNode("my-code-only", null, [
              _createElementVNode("div", { "v-if": "presentFromParams" }, [
                _createTextVNode("\n  "),
                _createElementVNode("div", null, "(パラメータ経由で) 太郎からもらったプレゼント："),
                _createTextVNode("\n  "),
                _createElementVNode("div", null, "{{presentFromParams}}"),
                _createTextVNode("\n")
              ]),
              _createTextVNode("\n"),
              _createElementVNode("div", { "v-if": "presentFromQuery" }, [
                _createTextVNode("\n  "),
                _createElementVNode("div", null, "(クエリ経由で) 太郎からもらったプレゼント："),
                _createTextVNode("\n  "),
                _createElementVNode("div", null, "{{presentFromQuery}}"),
                _createTextVNode("\n")
              ]),
              _createTextVNode("\n")
            ], -1)),
            _cache[76] || (_cache[76] = _createTextVNode("\n  ")),
            _createVNode(_component_my_show_only, null, {
              default: _withCtx(() => _cache[73] || (_cache[73] = [
                _createElementVNode("div", null, [
                  _createTextVNode("\n    "),
                  _createElementVNode("div", null, "(パラメータ経由で) 太郎からもらったプレゼント："),
                  _createTextVNode("\n    "),
                  _createElementVNode("div", null, [
                    _createElementVNode("span", { class: "highlight" }, "ディズニーランド ホテル(ウォールトディズニー) 無料宿泊券")
                  ]),
                  _createTextVNode("\n  ")
                ], -1),
                _createTextVNode("\n  "),
                _createElementVNode("div", null, "\n    または\n  ", -1),
                _createTextVNode("\n  "),
                _createElementVNode("div", null, [
                  _createTextVNode("\n    "),
                  _createElementVNode("div", null, "(クエリ経由で) 太郎からもらったプレゼント："),
                  _createTextVNode("\n    "),
                  _createElementVNode("div", null, [
                    _createElementVNode("span", { class: "highlight" }, "ディズニーシー ホテル(ミラコスタ) 無料宿泊券")
                  ]),
                  _createTextVNode("\n  ")
                ], -1),
                _createTextVNode("\n")
              ])),
              _: 1
            }),
            _cache[77] || (_cache[77] = _createTextVNode("\n"))
          ])
        ]),
        script: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[81] || (_cache[81] = _createTextVNode("import { ref } from 'vue';")),
            _createVNode(Marker, null, {
              default: _withCtx(() => _cache[78] || (_cache[78] = [
                _createTextVNode("\n// ① useRouteメソッドをインポート\nimport { useRoute } from 'vue-router';\n// ② Routeオブジェクトを作成\nconst route = useRoute();")
              ])),
              _: 1
            }),
            _cache[82] || (_cache[82] = _createTextVNode("\n\n")),
            _createVNode(Marker, null, {
              default: _withCtx(() => _cache[79] || (_cache[79] = [
                _createTextVNode("// ③ パラメータを取得\nconst presentFromParams = ref(route.params.present);")
              ])),
              _: 1
            }),
            _cache[83] || (_cache[83] = _createTextVNode("\n")),
            _createVNode(Marker, null, {
              default: _withCtx(() => _cache[80] || (_cache[80] = [
                _createTextVNode("// ③ クエリを取得\nconst presentFromQuery = ref(route.query.present);")
              ])),
              _: 1
            }),
            _cache[84] || (_cache[84] = _createTextVNode("\n"))
          ])
        ]),
        style: _withCtx(() => _cache[85] || (_cache[85] = [
          _createElementVNode("pre", null, "", -1)
        ])),
        _: 1
      }),
      _createVNode(ExampleTemp, {
        id: 'useStore',
        list: list,
        pdfNextPageShow: true,
        isnoshow: true,
        isjscode: true
      }, {
        codeTitle: _withCtx(() => _cache[86] || (_cache[86] = [
          _createTextVNode(" @/store/index.js ")
        ])),
        script: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[88] || (_cache[88] = _createTextVNode("")),
            _createVNode(Marker, null, {
              default: _withCtx(() => _cache[87] || (_cache[87] = [
                _createTextVNode("const store = new vuex.Store({\n  state: {\n    count: 0\n  },\n  // ゲッター：ステート内容を加工＆取得\n  getters: {\n    // ソースコードの③からアクセスされる\n    getCount(state) {\n      return state.count;\n    }\n  },\n  // ミューテーション：ストアの状態を操作\n  mutations: {\n    // ソースコードの④からアクセスされる\n    increment(state) {\n      state.count++;\n    }\n  },\n  // アクション：非同期処理を実装\n  actions: {\n  },\n  // モジュール：巨大なストアを分割管理\n  modules: {\n  }\n});\n\nexport default store;")
              ])),
              _: 1
            }),
            _cache[89] || (_cache[89] = _createTextVNode("\n"))
          ])
        ]),
        _: 1
      }),
      _createVNode(ExampleTemp, null, {
        template: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[97] || (_cache[97] = _createTextVNode("")),
            _cache[98] || (_cache[98] = _createElementVNode("my-code-only", null, [
              _createElementVNode("div", null, [
                _createTextVNode("\n  カウンター："),
                _createElementVNode("span", { class: "highlight" }, "{{count}}"),
                _createTextVNode("\n")
              ])
            ], -1)),
            _cache[99] || (_cache[99] = _createTextVNode("\n")),
            _createVNode(_component_my_show_only, null, {
              default: _withCtx(() => [
                _createElementVNode("div", null, [
                  _cache[90] || (_cache[90] = _createTextVNode("\n  カウンター：")),
                  _createElementVNode("span", _hoisted_7, _toDisplayString(count.value), 1),
                  _cache[91] || (_cache[91] = _createTextVNode("\n"))
                ])
              ]),
              _: 1
            }),
            _cache[100] || (_cache[100] = _createTextVNode("\n\n")),
            _createElementVNode("div", null, [
              _createVNode(_component_my_code_only, null, {
                default: _withCtx(() => _cache[92] || (_cache[92] = [
                  _createTextVNode("\n  <button class=\"btn btn-white\" @click=\"increment()\">+1</button>")
                ])),
                _: 1
              }),
              _cache[95] || (_cache[95] = _createTextVNode("\n")),
              _createVNode(_component_my_show_only, null, {
                default: _withCtx(() => [
                  _cache[93] || (_cache[93] = _createTextVNode("\n  ")),
                  _createElementVNode("button", {
                    class: "btn btn-white",
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (increment()))
                  }, "+1"),
                  _cache[94] || (_cache[94] = _createTextVNode("\n"))
                ]),
                _: 1
              }),
              _cache[96] || (_cache[96] = _createTextVNode("\n\n"))
            ]),
            _cache[101] || (_cache[101] = _createTextVNode("\n"))
          ])
        ]),
        script: _withCtx(() => [
          _createElementVNode("pre", null, [
            _cache[103] || (_cache[103] = _createTextVNode("")),
            _createVNode(Marker, null, {
              default: _withCtx(() => _cache[102] || (_cache[102] = [
                _createTextVNode("// ① useStoreメソッドをインポート\nimport { useStore } from 'vuex';\n// ② storeオブジェクトを作成\nconst store = useStore();\n// ③ refオブジェクトを作成(初期値にstoreの値を代入)\nconst count = ref(store.getters.getCount);\n// ④ カウンターボタンがクリックされた際実行される関数を準備\nconst increment = () => {\n  // ④ commitメソッドを使ってstoreに反映\n  store.commit('increment');\n  // ⑤ Refオブジェクトにも同期しテンプレートにリアルタイムに反映\n  count.value++;\n}")
              ])),
              _: 1
            }),
            _cache[104] || (_cache[104] = _createTextVNode("\n"))
          ])
        ]),
        style: _withCtx(() => _cache[105] || (_cache[105] = [
          _createElementVNode("pre", null, "", -1)
        ])),
        _: 1
      }),
      _createVNode(PdfNextPage),
      _createElementVNode("div", _hoisted_8, [
        _cache[133] || (_cache[133] = _createElementVNode("h4", { class: "text-center" }, "ノート", -1)),
        _createElementVNode("ul", _hoisted_9, [
          _createElementVNode("li", null, [
            _cache[122] || (_cache[122] = _createTextVNode(" 三項演算子とは ")),
            _createElementVNode("div", null, [
              _cache[107] || (_cache[107] = _createTextVNode(" 三項演算子（さんこうえんざんし）とは、プログラミングにおいて条件分岐を簡潔に記述するための構文です。通常、次のような形式で表現されます：")),
              _cache[108] || (_cache[108] = _createElementVNode("br", null, null, -1)),
              _cache[109] || (_cache[109] = _createElementVNode("br", null, null, -1)),
              _cache[110] || (_cache[110] = _createTextVNode(" 条件式 ? 真の場合の値 : 偽の場合の値")),
              _cache[111] || (_cache[111] = _createElementVNode("br", null, null, -1)),
              _cache[112] || (_cache[112] = _createElementVNode("br", null, null, -1)),
              _cache[113] || (_cache[113] = _createTextVNode(" この構文を使うと、条件が真の場合には最初の値が、偽の場合には二番目の値が返されます。例えば、年齢に基づいて「成人」か「未成年」を判定する場合、次のように書けます：")),
              _cache[114] || (_cache[114] = _createElementVNode("br", null, null, -1)),
              _cache[115] || (_cache[115] = _createElementVNode("br", null, null, -1)),
              _createVNode(Code, null, {
                default: _withCtx(() => _cache[106] || (_cache[106] = [
                  _createElementVNode("pre", null, "const age = 27;\nconst result = age >= 18 ? \"成人\" : \"未成年\";\nconsole.log(result)\n// → 成人\n", -1)
                ])),
                _: 1
              }),
              _cache[116] || (_cache[116] = _createElementVNode("br", null, null, -1)),
              _cache[117] || (_cache[117] = _createTextVNode(" この例では、ageが18以上ならresultに「成人」が、そうでなければ「未成年」が代入されます。")),
              _cache[118] || (_cache[118] = _createElementVNode("br", null, null, -1)),
              _cache[119] || (_cache[119] = _createTextVNode(" 三項演算子は、if-else文の省略形として使われ、コードを短くし、可読性を向上させるのに役立ちます。ただし、複雑な条件や多くの分岐がある場合は、かえって可読性が低下することもあるので、適切な使用が重要です。")),
              _cache[120] || (_cache[120] = _createElementVNode("br", null, null, -1)),
              _cache[121] || (_cache[121] = _createElementVNode("br", null, null, -1))
            ])
          ]),
          _createElementVNode("li", null, [
            _cache[132] || (_cache[132] = _createTextVNode(" Null合体演算子とは ")),
            _createElementVNode("div", null, [
              _cache[124] || (_cache[124] = _createTextVNode(" Null合体演算子（nullish coalescing operator）を使用して、nullまたはundefinedの値を簡単に処理できます。この演算子は「??」で表され、左辺がnullまたはundefinedの場合に右辺の値を返し、それ以外の場合は左辺の値を返します。")),
              _cache[125] || (_cache[125] = _createElementVNode("br", null, null, -1)),
              _cache[126] || (_cache[126] = _createElementVNode("br", null, null, -1)),
              _cache[127] || (_cache[127] = _createTextVNode(" 条件式(真の場合の値) ?? 偽の場合の値")),
              _cache[128] || (_cache[128] = _createElementVNode("br", null, null, -1)),
              _cache[129] || (_cache[129] = _createElementVNode("br", null, null, -1)),
              _createVNode(Code, null, {
                default: _withCtx(() => _cache[123] || (_cache[123] = [
                  _createElementVNode("pre", null, "let name = null;\nconst result = name ?? \"匿名\";\nconsole.log(result)\n// → 匿名\n\nname = undefined;\nconst result = name ?? \"匿名\";\nconsole.log(result)\n// → 匿名\n\nname = \"花子\";\nconst result = name ?? \"匿名\";\nconsole.log(result)\n// → 花子\n", -1)
                ])),
                _: 1
              }),
              _cache[130] || (_cache[130] = _createElementVNode("br", null, null, -1)),
              _cache[131] || (_cache[131] = _createTextVNode(" この例ではnameがnullまたはundefinedの場合「匿名」が代入され、nameが文字列の場合、「花子」が代入されます。 Null合体演算子を使うことで、デフォルト値を簡単に設定でき、コードの可読性と安全性を向上させることができます。 "))
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})