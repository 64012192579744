<template>
  <Javascript />  
</template>

<script lang="ts">
import { defineComponent } from 'vue';

// Components
import Javascript from '@/components/views/JavascriptComponent.vue';

export default defineComponent({
  components: {
    Javascript,
  },
});
</script>
